<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Generic Button', group: 'Button', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueButtonsGenericButton.jpg',
      description: 'Button that links',
      tags: ['Button', 'Link'],
      fields: {
        link: { type: 'link', label: 'Button Link' },
        centered: { type: 'checkbox', label: 'Centered?', default: true },
        inline: { type: 'checkbox', label: 'Inline?' },
      },
      templates: [{ label: 'Generic Button', fields: { link: { value: { text: 'DOWNLOAD THE DAILY ATLANTEAN', target: '_self', relationship: null, href: '\/new-page' } }, inline: { value: false } } }],
    })
  },
}
</script>

<template>
  <div :class="{ 'inline': fields.inline.value, 'flex': !fields.inline.value, ' justify-center items-center': fields.centered.value }">
    <glue-buttons-generic-button :devise="devise" />
  </div>
</template>
