<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Share On Facebook', group: 'Social', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesSocialShareOnFacebook.jpg',
      description: 'Share on Facebook',
      tags: ['Social', 'Vendor', 'Facebook'],

      fields: { shareUrl: { label: 'URL to share', type: 'text' } },
      templates: [{ label: 'Share On Facebook', fields: { shareUrl: { value: 'https:\/\/www.atlantisbahamas.com\/app\/scavengerhunt\/mission-completed?code=Oasis2023' } } }],
    })
    this.$nextTick(() => {
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id))
          return
        const js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    })
  },
}
</script>

<template>
  <div class="flex justify-center py-8">
    <div id="fb-root" />

    <!-- Your share button code -->
    <div class="fb-share-button" :data-href="fields.shareUrl.value" data-layout="button" size="large" />
  </div>
</template>
