<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Vertical Scroll Item', group: 'Vertical Scroll Navigation', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueVerticalScrollerVerticalScrollItem.jpg',
      description: 'Vertical Scroll Item',
      tags: ['Navigation'],

      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'wysiwyg', label: 'Description' },
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 1200, height: 800 }, sm: { width: 400, height: 400 } } },
        link: { type: 'link', label: 'Link', enabled: false },
      },
      templates: [{
        label: 'Vertical Scroll Item',
        fields: {
          description: { value: '<p>Located at The Coral Pool, the private Coral Cabanas offer families or friends a shady and relaxing place right next to the sparkling blue and white poolscape. Enjoy fun water activities and a swim-up bar.<br><br>Accommodates up to 6 people.<\/p>' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/aquaventure\/atlantis-the-coral-private-cabanas.jpeg' },
          title: { value: 'The Coral Pool' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <glue-vertical-scroller-vertical-scroll-item :devise="devise" />
  </div>
</template>
