<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

    mapStyles() {
      if (this.$voix.breakpoint.value === 'mobile' || this.$voix.breakpoint.value === 'tablet') {
        return {
          height: '600px',
          backgroundImage: 'url(\'/imgs/maps/flight-map.png\')',
          backgroundPosition: '-150px top',
        }
      }
      return {
        height: '800px',
        backgroundImage: 'url(\'/imgs/maps/flight-map.png\')',
      }
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Flight Map', group: 'Hard Coded Information', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
      preview: 'SlicesGlueMapFlightMap.jpg',
      description: 'World map with direct flights',
      tags: ['Map', 'Flight', 'Global Information', 'Link'],
      fields: { link: { type: 'link', label: 'Link', enabled: false } },
      templates: [{
        label: 'Flight Map',
        fields: {
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '\/new-page' } },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div
      class="relative flex flex-col items-end justify-center md:bg-center bg-no-repeat"
      :style="mapStyles"
    >
      <div class="hidden md:block bg-white p-12 mr-32">
        <div
          class="font-sans font-medium text-base uppercase mb-4 tracking-wider"
        >
          Fly Direct From:
        </div>
        <ul
          class="list-disc list-inside mb-8 font-sans2 text-xs text-gray-600 font-light"
          style="columns: 2; column-gap: 30px"
        >
          <li>Atlanta</li>
          <li>Baltimore</li>
          <li>Boston</li>
          <li>Charlotte</li>
          <li>Chicago</li>
          <li>Dallas</li>
          <li>Detroit</li>
          <li>Fort Lauderdale</li>
          <li>Houston</li>
          <li>Miami</li>
          <li>Minneapolis</li>
          <li>New York</li>
          <li>Orlando</li>
          <li>Philadelphia</li>
          <li>Washington DC</li>
          <li>West Palm Beach</li>
          <li>Calgary</li>
          <li>London</li>
          <li>Montreal</li>
          <li>Panama City</li>
          <li>Toronto</li>
        </ul>
        <a
          v-if="fields.link.enabled"
          :href="fields.link.value?.href"
          class="btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-active tracking-wider"
        >{{ fields.link.value.text }}</a>
      </div>
    </div>
    <div class="md:hidden bg-white p-12">
      <div class="font-sans font-medium text-lg uppercase mb-4 tracking-wider">
        Fly Direct From:
      </div>
      <ul
        class="list-disc list-inside mb-8 font-sans2 text-sm text-gray-600 font-light"
        style="columns: 2; column-gap: 30px"
      >
        <li>Atlanta</li>
        <li>Baltimore</li>
        <li>Boston</li>
        <li>Charlotte</li>
        <li>Chicago</li>
        <li>Dallas</li>
        <li>Detroit</li>
        <li>Fort Lauderdale</li>
        <li>Houston</li>
        <li>Miami</li>
        <li>Minneapolis</li>
        <li>New York</li>
        <li>Orlando</li>
        <li>Philadelphia</li>
        <li>Washington DC</li>
        <li>West Palm Beach</li>
        <li>Calgary</li>
        <li>London</li>
        <li>Montreal</li>
        <li>Panama City</li>
        <li>Toronto</li>
      </ul>
      <a
        v-if="fields.link.enabled"
        :href="fields.link.value?.href"
        class="btn-ghost uppercase text-xs text-gray-700 px-8 font-sans border-gray-500 inline-block text-center py-4 leading-none btn-active tracking-wider"
      >{{ fields.link.value.text }}</a>
    </div>
  </div>
</template>
