<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    backgroundStyle() {
      const style = {
        margin: `-1px auto ${this.fields.bottomOverlap.value}px auto`,
        padding: `${this.fields.topInnerPadding.value}px 0 0 0`,
      }
      return style
    },
    backgroundImage() {
      if (this.fields.background.value === 'waves')
        return 'url(/imgs/backgrounds/waves.svg)'

      if (this.fields.background.value === 'subtle-waves')
        return 'url(/imgs/backgrounds/subtle-waves.svg)'

      if (this.fields.background.value === 'leaves')
        return 'url(/imgs/backgrounds/leaves.png)'

      return 'url(/imgs/backgrounds/seamless-background.svg)'
    },
    backgroundSize() {
      if (this.fields.background.value === 'leaves')
        return '600px'

      if (this.fields.background.value === 'subtle-waves')
        return '1000px'

      return '400px'
    },
    opacity() {
      if (this.fields.background.value === 'leaves')
        return '0.1'

      return '1'
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Repeating Background', group: 'Backgrounds', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
      preview: 'SlicesGlueBackgroundsRepeatingBackground.jpg',
      slots: [{ name: 'default' }],
      tags: ['Background', 'Container'],
      description: 'Empty container with child slices',
      fields: {
        bottomOverlap: { type: 'number', label: 'Bottom Overlap Amount', default: '"0"' },
        topInnerPadding: { type: 'number', label: 'Top Inner Padding', default: '"1"' },
        background: { type: 'select', label: 'Background', options: {
          'floral': 'Floral (Default)',
          'waves': 'Waves',
          'subtle-waves': 'Subtle Waves',
          'leaves': 'Leaves',
        }, defaults: { value: 'floral' } },
        innerContainer: { type: 'checkbox', label: 'Inner Container', default: true },
      },
      templates: [{
        label: 'Repeating Background',
        fields: { background: { value: 'waves' } },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div class="relative bg-repeat" :style="backgroundStyle">
      <div class="absolute inset-0 z-0" :style="{ backgroundImage, backgroundSize, opacity }" />
      <div
        class="relative z-10"
        :style="{
          marginTop: `-${fields.bottomOverlap.value}px`,
          transform: `translateY(${fields.bottomOverlap.value}px)`,
        }"
      >
        <div
          :class="{
            container: fields.innerContainer.value,
          }"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
