<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: {
        label: 'Inline Activities Calendar',
        group: 'Activities',
        layouts: ['Atlantisbahamas', 'AtlantisMobileApp'],
      },
      preview: 'SlicesGlueActivitiesInlineActivitiesCalendar.jpg',
      description: 'Information Block',
      tags: ['Activity', 'Calendar'],
      fields: {
        image: { type: 'media', label: 'Image', enabled: false, breakpoints: { lg: { width: 600, height: 450 }, default: { width: 315, height: 315 } } },
        title: { type: 'text', label: 'Title', editorLabel: true, default: '"BOOK YOUR EXPERIENCE TODAY"' },
        copy: { type: 'wysiwyg', label: 'Introduction', enabled: true },
        buttonLabel: { type: 'text', label: 'Button Label', default: '"Search"' },
        mode: { type: 'select', label: 'Date Mode', options: { inline: 'Single Date', duo: 'Two Dates' } },
      },
      templates: [{
        label: 'Inline Activities Calendar',
        fields: {
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-mariine-programs-dolphin-cay-dip-n-discover-playtime-dolphins.jpg' },
          mode: { value: 'duo' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <div class="container mb-24 text-align-center">
      <div class="flex items-center justify-center p-8">
        <GlueActivitiesSearchActivities
          origin="https%3A%2F%2Fatlantisbahamas.com%2F%2F%2F"
          prefill-check-in=""
          prefill-check-out="" :devise="devise"
        />
      </div>
    </div>
  </div>
</template>
