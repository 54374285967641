<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Memory Game', group: 'Game', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesTwentyFifthAnniversaryMemoryGame.jpg',
      description: 'Memory Game',
      tags: ['Game', '25th Anniversary'],
      fields: {
        instructions: { type: 'link', label: 'Instructions Link' },
        terms: { type: 'wysiwyg', label: 'Terms Text' },
        emailOptInText: { type: 'wysiwyg', label: 'Email Opt In Text' },
      },
      templates: [{ label: 'Memory Game', fields: { terms: { value: '<p>To add my initials, I acknowledge I am over the age of 18, have read the<a href="\/privacy" rel="noopener noreferrer nofollow"> Privacy Policy<\/a>, <a href="\/terms" rel="noopener noreferrer nofollow">Terms &amp; Conditions<\/a>. <\/p><p><\/p>' }, instructions: { value: { text: 'HOW TO PLAY', target: '_self', relationship: null, href: '\/new-page' } }, emailOptInText: { value: '<p>I would like to receive emails on promotions, special offers and updates from Atlantis Paradise Island and its affiliated brands.<\/p>' } } }],
    })
  },
}
</script>

<template>
  <div>
    <TwentyFifthAnniversaryMemoryGame :devise="devise" />
  </div>
</template>
