<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Side Clipped Slider', group: 'Sliders', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
      preview: 'SlicesGlueSlidersSideClippedSlider.jpg',
      description: 'Slider of Images that is clipped on the left or right',
      tags: ['Slider'],

      slots: [{ name: 'default' }],
      fields: { isRight: { label: 'Clips to the right', type: 'checkbox' }, title: { type: 'text', label: 'Title' }, description: { type: 'textarea', label: 'Description' }, autoPlay: { type: 'checkbox', label: 'Auto Play' } },
      templates: [{ label: 'Side Clipped Slider', fields: { description: { value: 'Incorporating a wide variety of ingredients from crustaceans to tuna,\nlionfish to conch, preparations will range from simple and flavorful to\ninnovative and creative.' }, title: { value: 'signature dishes' } } }],
    })
  },
}
</script>

<template>
  <div
    :class="{
      'big-container-left': !fields.isRight.value && windowWidth <= 1680,
      'big-container-right': fields.isRight.value && windowWidth <= 1680,
      'big-container-center': windowWidth > 1680,
    }"
  >
    <div style="height:0;">
&nbsp;
    </div>
    <glue-sliders-side-clipping-slider ref="slider" :devise="devise">
      <slot />
    </glue-sliders-side-clipping-slider>
  </div>
</template>
