<script>
defineSlice({
  name: { label: 'Wild Kingdom Video', group: 'Videos', layouts: ['Atlantisbahamas'] },
  preview: 'SlicesVideoWildKingdom.jpg',
  tags: ['Videos', 'SingleUse', 'Youtube'],
  fields: { },
  templates: [],
})
</script>

<template>
  <iframe class="mx-auto my-12" width="560" height="315" src="https://www.youtube.com/embed/cnYUOcSJfr8?si=eACzd-iNTJm8oKgf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
</template>
