<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      deprecated: true,
      name: { label: 'Slice Preview Category', group: 'Documentation', layouts: ['*'] },
      preview: 'SlicesDocumentationSlicePreviewCategory.jpg',
      slots: [{ name: 'default' }],
      tags: ['Documentation'],
      description: 'Categories for the ',
      fields: { categoryName: { type: 'text', label: 'Category Name' } },
      templates: [{
        label: 'Slice Preview Category',
        fields: { categoryName: { value: 'Heroes' } },
      }],
    })
  },
}
</script>

<template>
  <div>
    <slot />
  </div>
</template>
