<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filters: {
        categories: [],
      },
      pageRestaurants: [],
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, {
        fields: this.fields,
        page: this.currentPage,
        slice: this.slice,
      })
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Dining Results', group: 'Glue', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
      preview: 'SlicesGlueDiningDiningResults.jpg',
      description: 'Search results for dining section of Atlantisbahamas.com',
      tags: ['Dining', 'Search'],

      slots: [{ name: 'default' }],
      fields: {
        showDiningTimes: { label: 'Show dining times', type: 'checkbox' },
        category: {
          label: 'Category',
          instructions: '"Leave Blank For All"',
          type: 'select',
          options: {
            'Breakfast': 'Breakfast',
            'Specialty Dining': 'Specialty Dining',
            'Casual Dining': 'Casual Dining',
            'Bars, Lounges & Clubs': 'Bars & Lounges',
            'Quick Bites': 'Quick Bites',
            'Patio Dining': 'Outdoor Seating',
            'Fine Dining': 'Fine Dining',
            'Contemporary Casual': 'Contemporary Casual',
            'Buffet': 'Buffet',
            'Celebrity Chef': 'Celebrity Chef',
          },
        },
      },

      templates: [{ label: 'Dining Results', fields: { showDiningTimes: { value: true } } }],
    })
    this.requestRestaurants()
  },

  methods: {
    requestRestaurants() {
      $voixFetch('/api/devise/all-restaurants')
        .then((response) => {
          this.pageRestaurants = response
        })
    },
  },
}
</script>

<template>
  <div>
    <GlueDiningDiningSearch
      v-if="pageRestaurants.data"
      :restaurants="pageRestaurants.data"
      :devise="devise"
    >
      <slot />
    </GlueDiningDiningSearch>
  </div>
</template>
