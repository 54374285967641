<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Full Width Container', group: 'Containers', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesContainersFullWidthContainer.jpg',
      slots: [{ name: 'default' }],
      description: 'Wrapper that runs the full width. Useful for paddings and margins with background color.',
      tags: ['Container'],
      templates: [{ label: 'Full Width Container', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="mb-24">
    <slot />
  </div>
</template>
