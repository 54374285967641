<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Static Gridded Gallery Slot Five', group: 'Galleries', layouts: ['Atlantisbahamas'] },
      description: 'Adds a gallery to the bottom-right slot on the gridded gallery',
      tags: ['Gallery', 'Image', 'Static Gridded Gallery'],
      slots: [{ name: 'default' }],
      fields: { title: { type: 'text', label: 'Title on Hover' } },
      templates: [{ label: 'Static Gridded Gallery Slot Five', fields: { title: { value: 'Karaya nursing' } } }],
    })
  },
}
</script>

<template>
  <glue-galleries-static-gridded-gallery-gallery :devise="devise" gallery-name="Five">
    <slot />
  </glue-galleries-static-gridded-gallery-gallery>
</template>
