<script>
import dayjs from 'dayjs'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      bookingParams: null,
      name: null,
      email: null,
      phone: null,
      agreeToTerms: false,
      formSubmitted: false,
      noCookie: false,
      isFlexible: true,
    }
  },

  computed: {
    hotel() {
      if (this.bookingParams)
        return this.bookingParams.hotel

      return null
    },
    checkin() {
      if (this.bookingParams)
        return dayjs(this.bookingParams.range.start).format('MMM D, YYYY')

      return null
    },
    checkout() {
      if (this.bookingParams)
        return dayjs(this.bookingParams.range.end).format('MMM D, YYYY')

      return null
    },

    rooms() {
      if (this.bookingParams)
        return this.bookingParams.rooms

      return null
    },

    isValid() {
      return (
        this.name !== null
        && this.email !== null
        && this.agreeToTerms === true
        && this.bookingParams !== null
      )
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Waiting List', group: 'Waiting List', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueWaitingListWaitingList.jpg',
      description: 'Waiting list form',
      fields: [],
      templates: [{ label: 'Waiting List', fields: [] }],
    })
    try {
      const bookingParamsWitingList = useCookie('booking-params-waitlist')
      this.bookingParams = JSON.parse(bookingParamsWitingList)
    }
    catch (e) {
      this.noCookie = true
    }
  },

  methods: {
    async requestAddToQueue() {
      await $voixFetch(`/api/devise/wait-list`, {
        method: 'POST',
        body: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          hotel: this.hotel.name,
          check_in: dayjs(this.checkin).format('YYYY-MM-DD'),
          check_out: dayjs(this.checkout).format('YYYY-MM-DD'),
          flexible_dates: this.isFlexible,
          booking_params: this.bookingParams,
          rooms: JSON.stringify(this.rooms),
          agreed_to_terms: this.agreeToTerms,
        },
      })
      this.formSubmitted = true
    },
  },
}
</script>

<template>
  <div class="bg-orange-50 py-16">
    <div class="container mx-auto">
      <div class="bg-glueblue-600 text-white">
        <div class="relative bg-white font-sans2">
          <div class="lg:absolute lg:inset-0">
            <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                class="h-56 w-full object-cover lg:absolute lg:h-full"
                src="/imgs/heros/waiting-list.jpg"
                alt=""
              >
            </div>
          </div>
          <div
            class="relative py-16 px-4 sm:py-24 sm:px-8 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2"
          >
            <div class="lg:pr-8">
              <div class="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <template v-if="!formSubmitted && !noCookie">
                  <h2 class="text-3xl tracking-tight sm:text-4xl text-orange-600">
                    Jump in line!
                  </h2>
                  <p class="mt-4 text-lg text-gray-500 sm:mt-3">
                    At Atlantis, we occasionally have opening unexpectedly open up. If you
                    would like to be notified about the date ranges you were searching for
                    we will reach out and let you know if they become available.
                  </p>

                  <div class="mt-8 bg-gray-100 pb-2">
                    <div
                      class="font-sans2 uppercase text-base tracking-tight sm:text-base text-glueblue-800 px-8 pt-8"
                    >
                      What you were looking for:
                    </div>
                    <div class="mt-4 border-t border-gray-200 px-8 py-5 sm:p-0">
                      <dl class="sm:divide-y sm:divide-gray-300">
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
                          <dt class="text-sm font-medium text-gray-500">
                            Hotel
                          </dt>
                          <dd
                            v-if="hotel"
                            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                          >
                            {{ hotel.name }}
                          </dd>
                        </div>
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
                          <dt class="text-sm font-medium text-gray-500">
                            Check In
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ checkin }}
                          </dd>
                        </div>
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-8">
                          <dt class="text-sm font-medium text-gray-500">
                            Check Out
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ checkout }}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <form
                    action="#"
                    method="POST"
                    class="mt-16 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                    @submit.prevent="requestAddToQueue"
                  >
                    <div class="sm:col-span-2">
                      <div class="flex justify-between">
                        <label for="name" class="block text-sm font-medium text-gray-700">Full Name</label>
                        <span id="email-description" class="text-sm text-gray-800">Required</span>
                      </div>
                      <div class="mt-1">
                        <input
                          id="name"
                          v-model="name"
                          type="text"
                          name="name"
                          autocomplete="name"
                          class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
                        >
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <div class="flex justify-between">
                        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                        <span id="email-description" class="text-sm text-gray-800">Required</span>
                      </div>
                      <div class="mt-1">
                        <input
                          id="email"
                          v-model="email"
                          name="email"
                          type="email"
                          autocomplete="email"
                          class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
                        >
                      </div>
                    </div>

                    <div class="sm:col-span-2">
                      <div class="flex justify-between">
                        <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
                        <span id="phone-description" class="text-sm text-gray-500">Optional</span>
                      </div>
                      <div class="mt-1">
                        <input
                          id="phone"
                          v-model="phone"
                          type="text"
                          name="phone"
                          autocomplete="tel"
                          aria-describedby="phone-description"
                          class="block w-full shadow-sm sm:text-sm focus:ring-glueblue-500 focus:border-glueblue-500 border-gray-300 border py-2 px-4 rounded-md"
                        >
                      </div>
                    </div>

                    <div class="sm:col-span-2 relative flex items-center w-full">
                      <div class="flex items-center h-5">
                        <input
                          id="flexible"
                          v-model="isFlexible"
                          aria-describedby="agree to terms"
                          name="flexible"
                          type="checkbox"
                          class="focus:ring-glueblue-500 h-4 w-4 text-glueblue-600 border-gray-300 rounded"
                        >
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="flexible" class="font-medium text-gray-700">
                          Flexible Dates?
                        </label>
                      </div>
                    </div>

                    <div class="relative flex items-center">
                      <div class="flex items-center h-5">
                        <input
                          id="agreeToTerms"
                          v-model="agreeToTerms"
                          aria-describedby="agree to terms"
                          name="agreeToTerms"
                          type="checkbox"
                          class="focus:ring-glueblue-500 h-4 w-4 text-glueblue-600 border-gray-300 rounded"
                        >
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="agreeToTerms" class="font-medium text-gray-700">
                          <a
                            href="https://www.atlantisbahamas.com/privacy"
                            target="_blank"
                            class="underline"
                          >
                            Agree to terms
                          </a>
                          (Required)
                        </label>
                      </div>
                    </div>

                    <div class="sm:col-span-2">
                      <button
                        type="submit"
                        class="glue-btn btn bg-glueblue-600 btn-sm"
                        :disabled="!isValid"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </template>

                <template v-else-if="formSubmitted && !noCookie">
                  <h2 class="text-3xl tracking-tight sm:text-4xl text-orange-600">
                    We will be in touch soon!
                  </h2>
                  <p class="mt-4 text-lg text-gray-500 sm:mt-3">
                    Ok, we've added you to the queue! However, it's not a guarantee that
                    rooms will become available so do try searching at one of our other
                    hotels and we'll be happy to transfer you for no additional fees to
                    your original search if things change.
                  </p>
                </template>

                <template v-else-if="noCookie">
                  <h2 class="text-3xl tracking-tight sm:text-4xl text-orange-600">
                    Atlantis Booking
                  </h2>
                  <p class="mt-4 text-lg text-gray-500 sm:mt-3">
                    We were unable to retrieve your booking information. Please try
                    booking a room at one of our other hotel options with your query at
                    <a href="/">Atlantisbahamas.com</a>.
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
