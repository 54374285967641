<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Image Card', group: 'Media', layouts: ['NPIWFF', 'Atlantisbahamas'] },
      preview: 'SlicesWffImageCard.jpg',
      description: 'Card with square image and description',
      tags: ['Card', 'Media'],

      fields: {
        mode: { type: 'select', label: 'Mode', options: { imageCopy: 'Image and Copy', imageOnly: 'Image Only' } },
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 250, height: 250 } } },
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link', enabled: false },
      },
      templates: [
        {
          label: 'Image Only',
          fields: {
            mode: { value: 'imageOnly' },
            copy: { value: '<p>Reside in a luxurious suite in The Royal, The Cove, The Reef, or The Coral.<\/p>' },
            image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Offers\/2020\/reef-extended-stay\/reef-topaz-suite-lr.jpg' },
            title: { value: 'A five-night stay' },
          },
        },
        {
          label: 'Image With Content',
          fields: {
            mode: { value: 'imageCopy' },
            copy: { value: '<p>Reside in a luxurious suite in The Royal, The Cove, The Reef, or The Coral.<\/p>' },
            image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Offers\/2020\/reef-extended-stay\/reef-topaz-suite-lr.jpg' },
            title: { value: 'A five-night stay' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div
    class="relative pt-8 lg:pt-0 flex flex-col items-center"
    :class="{
      'cursor-pointer': fields.link.enabled,
      'lg:w-[250px] max-w-[350px]': fields.mode.value === 'imageCopy',
      'lg:w-[350px] lg:h-[350px]': fields.mode.value === 'imageOnly',
    }"
    @click="clickedCard"
  >
    <ClientOnly>
      <VoixMedia v-if="fields.mode.value === 'imageCopy'" :field="fields.image" />

      <div v-else-if="fields.mode.value === 'imageOnly' && fields.link.enabled" class="w-full h-full">
        <a :href="fields.link.value?.href" :target="fields.link.value?.target">
          <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
        </a>
      </div>

      <VoixMedia v-else :field="fields.image" class="object-cover w-full h-full" />
    </ClientOnly>

    <div v-if="fields.mode.value === 'imageOnly'" class="absolute bottom-6 left-6 ">
      <div class="relative z-10 font-sans2 text-2xl uppercase text-white font-bold">
        {{ fields.title.value }}
      </div>
    </div>
    <div v-if="fields.mode.value === 'imageOnly'" class="absolute left-0 bottom-0 right-0  z-0 h-[100px] bg-gradient-to-t from-black/40 to-transparent" />

    <div v-if="fields.mode.value === 'imageCopy'" class="p-6 py-8">
      <div class="font-sans2 uppercase text-glueblue-600">
        {{ fields.title.value }}
      </div>
      <div class="mt-4 wywiwyg font-sans2 text-xs" v-html="fields.copy.value" />
      <a v-if="fields.link.enabled" :href="fields.link.value?.href" :target="fields.link.value?.target" class="mt-3 font-sans uppercase text-2xs text-glueblue-600 flex space-x-2">
        <span>{{ fields.link.value?.text }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </a>
    </div>
  </div>
</template>
