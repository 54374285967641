<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Circle Slider', group: 'Slider', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueSlidersCircleSlider.jpg',
      description: 'Slider of Circles',
      tags: ['Slider'],
      slots: [{ name: 'default' }],
      fields: { title: { label: 'Title', type: 'text' }, link: { label: 'Link', type: 'link', enabled: false } },
      templates: [{ label: 'Circle Slider', fields: { title: { value: 'Test of the Circle Slider' } } }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <summer-circle-slider :devise="devise">
      <slot />
    </summer-circle-slider>
  </div>
</template>
