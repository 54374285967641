<script>
export default {
  data() {
    return {
      voixApiBaseUrl: '',
      agency: null,
      iata: null,
      street: null,
      city: null,
      state: null,
      zip: null,
      country: 'United States',
      agencyContact: null,
      phone: null,
      email: null,
      formsUploaded: false,
      agreeToTerms: false,
      showError: false,
      uploadedFiles: [],
    }
  },
  computed: {
    url() {
      if (process.client)
        return `${window.location.href}?submitted=true`

      return ''
    },
    submitted() {
      if (process.client)
        return window.location.search.includes('submitted=true')

      return false
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Travel Agents Registration', group: 'Travel Agents', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesTravelAgentsRegistration.jpg',
      description: 'Travel Agents Registration Slice',
      tags: ['Travel Agents', 'Registration', 'Form', 'Account'],

      templates: [{
        label: 'Travel Agents',
        fields: {},
      }],
    })
    const config = useRuntimeConfig()
    this.voixApiBaseUrl = config.public.voixApiBaseUrl
  },
  methods: {
    submit() {
      if (
        this.agency
        && this.iata
        && this.street
        && this.city
        && this.state
        && this.zip
        && this.agencyContact
        && this.phone
        && this.email
        && this.uploadedFiles.length > 0
        && this.agreeToTerms
      ) {
        this.$refs.masteroftheuniverse.submit()
      }
      else {
        this.showError = true
      }
    },
    handleFileUpload(event) {
      this.uploadedFiles = Array.from(event.target.files)
      this.formsUploaded = this.uploadedFiles.length > 0
    },
  },
}
</script>

<template>
  <div class="py-24 mb-24">
    <div class="container">
      <div class="bg-white p-12">
        <div class="px-12 mb-10">
          <h4 class="mb-4">
            THANK YOU FOR REGISTERING YOUR AGENCY WITH ATLANTIS PARADISE ISLAND
          </h4>
          <p>
            Once your agency has been added to our system, all agents in the agency will be able to login under your IATA, CLIA, ARC, OR TIDS. Each agent will not be able to register for an individual login. <span class="font-bold">Please Note:</span> You will not be eligible for commission until the agency has been verified, approved, and entered in our system.
          </p>
        </div>
        <ClientOnly>
          <form
            v-if="!submitted"
            ref="masteroftheuniverse"
            :action="`${voixApiBaseUrl}/travel-agent-registration?back=${url}`"
            method="post"
            enctype="multipart/form-data"
            @submit.prevent="submit"
          >
            <div class="flex justify-around mb-6">
              <fieldset class="w-2/5">
                <label for="agency_name">Agency Name
                  <span class="ml-1 text-red-500">*</span>
                </label>
                <input
                  id="agency_name" v-model="agency" type="text" name="agency_name"
                >
              </fieldset>

              <fieldset class="w-2/5">
                <label for="iata_num">IATA, CLIA, ARC, OR TIDS
                  <span class="ml-1 text-red-500">*</span>
                </label>
                <input
                  id="iata_num" v-model="iata" type="text"
                  name="iata_num"
                >
              </fieldset>
            </div>

            <div class="flex justify-around mb-6">
              <fieldset class="w-2/5">
                <label for="address">Address<span class="ml-1 text-red-500">*</span></label>
                <input
                  id="address" v-model="street" type="text"
                  class="mb-2" name="street_1" placeholder="Street 1"
                >
                <input
                  type="text" class="mb-2" name="street_2"
                  placeholder="Street 2"
                >
                <div class="flex">
                  <input
                    v-model="city" type="text" class="mb-2"
                    name="city" placeholder="City"
                  >
                  <input
                    v-model="state" type="text" class="mb-2 mx-2"
                    name="state" placeholder="State / Province"
                  >
                  <input
                    v-model="zip" type="text" class="mb-2" name="zip"
                    placeholder="Zip"
                  >
                </div>
                <select v-model="country" name="country">
                  <option value="" selected="selected">
                    Country
                  </option>
                  <option value="Afghanistan">
                    Afghanistan
                  </option>
                  <option value="Albania">
                    Albania
                  </option>
                  <option value="Algeria">
                    Algeria
                  </option>
                  <option value="American Samoa">
                    American Samoa
                  </option>
                  <option value="Andorra">
                    Andorra
                  </option>
                  <option value="Angola">
                    Angola
                  </option>
                  <option value="Anguilla">
                    Anguilla
                  </option>
                  <option value="Antarctica">
                    Antarctica
                  </option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">
                    Argentina
                  </option>
                  <option value="Armenia">
                    Armenia
                  </option>
                  <option value="Aruba">
                    Aruba
                  </option>
                  <option value="Australia">
                    Australia
                  </option>
                  <option value="Austria">
                    Austria
                  </option>
                  <option value="Azerbaijan">
                    Azerbaijan
                  </option>
                  <option value="Bahamas">
                    Bahamas
                  </option>
                  <option value="Bahrain">
                    Bahrain
                  </option>
                  <option value="Bangladesh">
                    Bangladesh
                  </option>
                  <option value="Barbados">
                    Barbados
                  </option>
                  <option value="Belarus">
                    Belarus
                  </option>
                  <option value="Belgium">
                    Belgium
                  </option>
                  <option value="Belize">
                    Belize
                  </option>
                  <option value="Benin">
                    Benin
                  </option>
                  <option value="Bermuda">
                    Bermuda
                  </option>
                  <option value="Bhutan">
                    Bhutan
                  </option>
                  <option value="Bolivia">
                    Bolivia
                  </option>
                  <option value="Bosnia and Herzegowina">
                    Bosnia and Herzegowina
                  </option>
                  <option value="Botswana">
                    Botswana
                  </option>
                  <option value="Bouvet Island">
                    Bouvet Island
                  </option>
                  <option value="Brazil">
                    Brazil
                  </option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">
                    Brunei Darussalam
                  </option>
                  <option value="Bulgaria">
                    Bulgaria
                  </option>
                  <option value="Burkina Faso">
                    Burkina Faso
                  </option>
                  <option value="Burundi">
                    Burundi
                  </option>
                  <option value="Cambodia">
                    Cambodia
                  </option>
                  <option value="Cameroon">
                    Cameroon
                  </option>
                  <option value="Canada">
                    Canada
                  </option>
                  <option value="Cape Verde">
                    Cape Verde
                  </option>
                  <option value="Cayman Islands">
                    Cayman Islands
                  </option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">
                    Chad
                  </option>
                  <option value="Chile">
                    Chile
                  </option>
                  <option value="China">
                    China
                  </option>
                  <option value="Christmas Island">
                    Christmas Island
                  </option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">
                    Colombia
                  </option>
                  <option value="Comoros">
                    Comoros
                  </option>
                  <option value="Congo">
                    Congo
                  </option>
                  <option value="Congo, the Democratic Republic of the">
                    Congo, the Democratic Republic
                    of the
                  </option>
                  <option value="Cook Islands">
                    Cook Islands
                  </option>
                  <option value="Costa Rica">
                    Costa Rica
                  </option>
                  <option value="Cote d'Ivoire">
                    Cote d'Ivoire
                  </option>
                  <option value="Croatia (Hrvatska)">
                    Croatia (Hrvatska)
                  </option>
                  <option value="Cuba">
                    Cuba
                  </option>
                  <option value="Cyprus">
                    Cyprus
                  </option>
                  <option value="Czech Republic">
                    Czech Republic
                  </option>
                  <option value="Denmark">
                    Denmark
                  </option>
                  <option value="Djibouti">
                    Djibouti
                  </option>
                  <option value="Dominica">
                    Dominica
                  </option>
                  <option value="Dominican Republic">
                    Dominican Republic
                  </option>
                  <option value="East Timor">
                    East Timor
                  </option>
                  <option value="Ecuador">
                    Ecuador
                  </option>
                  <option value="Egypt">
                    Egypt
                  </option>
                  <option value="El Salvador">
                    El Salvador
                  </option>
                  <option value="Equatorial Guinea">
                    Equatorial Guinea
                  </option>
                  <option value="Eritrea">
                    Eritrea
                  </option>
                  <option value="Estonia">
                    Estonia
                  </option>
                  <option value="Ethiopia">
                    Ethiopia
                  </option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">
                    Faroe Islands
                  </option>
                  <option value="Fiji">
                    Fiji
                  </option>
                  <option value="Finland">
                    Finland
                  </option>
                  <option value="France">
                    France
                  </option>
                  <option value="France Metropolitan">
                    France Metropolitan
                  </option>
                  <option value="French Guiana">
                    French Guiana
                  </option>
                  <option value="French Polynesia">
                    French Polynesia
                  </option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">
                    Gabon
                  </option>
                  <option value="Gambia">
                    Gambia
                  </option>
                  <option value="Georgia">
                    Georgia
                  </option>
                  <option value="Germany">
                    Germany
                  </option>
                  <option value="Ghana">
                    Ghana
                  </option>
                  <option value="Gibraltar">
                    Gibraltar
                  </option>
                  <option value="Greece">
                    Greece
                  </option>
                  <option value="Greenland">
                    Greenland
                  </option>
                  <option value="Grenada">
                    Grenada
                  </option>
                  <option value="Guadeloupe">
                    Guadeloupe
                  </option>
                  <option value="Guam">
                    Guam
                  </option>
                  <option value="Guatemala">
                    Guatemala
                  </option>
                  <option value="Guinea">
                    Guinea
                  </option>
                  <option value="Guinea-Bissau">
                    Guinea-Bissau
                  </option>
                  <option value="Guyana">
                    Guyana
                  </option>
                  <option value="Haiti">
                    Haiti
                  </option>
                  <option value="Heard and Mc Donald Islands">
                    Heard and Mc Donald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">
                    Honduras
                  </option>
                  <option value="Hong Kong">
                    Hong Kong
                  </option>
                  <option value="Hungary">
                    Hungary
                  </option>
                  <option value="Iceland">
                    Iceland
                  </option>
                  <option value="India">
                    India
                  </option>
                  <option value="Indonesia">
                    Indonesia
                  </option>
                  <option value="Iran (Islamic Republic of)">
                    Iran (Islamic Republic of)
                  </option>
                  <option value="Iraq">
                    Iraq
                  </option>
                  <option value="Ireland">
                    Ireland
                  </option>
                  <option value="Israel">
                    Israel
                  </option>
                  <option value="Italy">
                    Italy
                  </option>
                  <option value="Jamaica">
                    Jamaica
                  </option>
                  <option value="Japan">
                    Japan
                  </option>
                  <option value="Jordan">
                    Jordan
                  </option>
                  <option value="Kazakhstan">
                    Kazakhstan
                  </option>
                  <option value="Kenya">
                    Kenya
                  </option>
                  <option value="Kiribati">
                    Kiribati
                  </option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's
                    Republic of
                  </option>
                  <option value="Korea, Republic of">
                    Korea, Republic of
                  </option>
                  <option value="Kuwait">
                    Kuwait
                  </option>
                  <option value="Kyrgyzstan">
                    Kyrgyzstan
                  </option>
                  <option value="Lao, People's Democratic Republic">
                    Lao, People's Democratic
                    Republic
                  </option>
                  <option value="Latvia">
                    Latvia
                  </option>
                  <option value="Lebanon">
                    Lebanon
                  </option>
                  <option value="Lesotho">
                    Lesotho
                  </option>
                  <option value="Liberia">
                    Liberia
                  </option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">
                    Liechtenstein
                  </option>
                  <option value="Lithuania">
                    Lithuania
                  </option>
                  <option value="Luxembourg">
                    Luxembourg
                  </option>
                  <option value="Macau">
                    Macau
                  </option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former
                    Yugoslav Republic
                    of
                  </option>
                  <option value="Madagascar">
                    Madagascar
                  </option>
                  <option value="Malawi">
                    Malawi
                  </option>
                  <option value="Malaysia">
                    Malaysia
                  </option>
                  <option value="Maldives">
                    Maldives
                  </option>
                  <option value="Mali">
                    Mali
                  </option>
                  <option value="Malta">
                    Malta
                  </option>
                  <option value="Marshall Islands">
                    Marshall Islands
                  </option>
                  <option value="Martinique">
                    Martinique
                  </option>
                  <option value="Mauritania">
                    Mauritania
                  </option>
                  <option value="Mauritius">
                    Mauritius
                  </option>
                  <option value="Mayotte">
                    Mayotte
                  </option>
                  <option value="Mexico">
                    Mexico
                  </option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">
                    Monaco
                  </option>
                  <option value="Mongolia">
                    Mongolia
                  </option>
                  <option value="Montserrat">
                    Montserrat
                  </option>
                  <option value="Morocco">
                    Morocco
                  </option>
                  <option value="Mozambique">
                    Mozambique
                  </option>
                  <option value="Myanmar">
                    Myanmar
                  </option>
                  <option value="Namibia">
                    Namibia
                  </option>
                  <option value="Nauru">
                    Nauru
                  </option>
                  <option value="Nepal">
                    Nepal
                  </option>
                  <option value="Netherlands">
                    Netherlands
                  </option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">
                    New Caledonia
                  </option>
                  <option value="New Zealand">
                    New Zealand
                  </option>
                  <option value="Nicaragua">
                    Nicaragua
                  </option>
                  <option value="Niger">
                    Niger
                  </option>
                  <option value="Nigeria">
                    Nigeria
                  </option>
                  <option value="Niue">
                    Niue
                  </option>
                  <option value="Norfolk Island">
                    Norfolk Island
                  </option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">
                    Norway
                  </option>
                  <option value="Oman">
                    Oman
                  </option>
                  <option value="Pakistan">
                    Pakistan
                  </option>
                  <option value="Palau">
                    Palau
                  </option>
                  <option value="Panama">
                    Panama
                  </option>
                  <option value="Papua New Guinea">
                    Papua New Guinea
                  </option>
                  <option value="Paraguay">
                    Paraguay
                  </option>
                  <option value="Peru">
                    Peru
                  </option>
                  <option value="Philippines">
                    Philippines
                  </option>
                  <option value="Pitcairn">
                    Pitcairn
                  </option>
                  <option value="Poland">
                    Poland
                  </option>
                  <option value="Portugal">
                    Portugal
                  </option>
                  <option value="Puerto Rico">
                    Puerto Rico
                  </option>
                  <option value="Qatar">
                    Qatar
                  </option>
                  <option value="Reunion">
                    Reunion
                  </option>
                  <option value="Romania">
                    Romania
                  </option>
                  <option value="Russian Federation">
                    Russian Federation
                  </option>
                  <option value="Rwanda">
                    Rwanda
                  </option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">
                    Saint Lucia
                  </option>
                  <option value="Saint Vincent and the Grenadines">
                    Saint Vincent and the Grenadines
                  </option>
                  <option value="Samoa">
                    Samoa
                  </option>
                  <option value="San Marino">
                    San Marino
                  </option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">
                    Saudi Arabia
                  </option>
                  <option value="Senegal">
                    Senegal
                  </option>
                  <option value="Seychelles">
                    Seychelles
                  </option>
                  <option value="Sierra Leone">
                    Sierra Leone
                  </option>
                  <option value="Singapore">
                    Singapore
                  </option>
                  <option value="Slovakia (Slovak Republic)">
                    Slovakia (Slovak Republic)
                  </option>
                  <option value="Slovenia">
                    Slovenia
                  </option>
                  <option value="Solomon Islands">
                    Solomon Islands
                  </option>
                  <option value="Somalia">
                    Somalia
                  </option>
                  <option value="South Africa">
                    South Africa
                  </option>
                  <option value="South Georgia and the South Sandwich Islands">
                    South Georgia and the
                    South Sandwich
                    Islands
                  </option>
                  <option value="Spain">
                    Spain
                  </option>
                  <option value="Sri Lanka">
                    Sri Lanka
                  </option>
                  <option value="St. Helena">
                    St. Helena
                  </option>
                  <option value="St. Pierre and Miquelon">
                    St. Pierre and Miquelon
                  </option>
                  <option value="Sudan">
                    Sudan
                  </option>
                  <option value="Suriname">
                    Suriname
                  </option>
                  <option value="Svalbard and Jan Mayen Islands">
                    Svalbard and Jan Mayen Islands
                  </option>
                  <option value="Swaziland">
                    Swaziland
                  </option>
                  <option value="Sweden">
                    Sweden
                  </option>
                  <option value="Switzerland">
                    Switzerland
                  </option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan, Province of China">
                    Taiwan, Province of China
                  </option>
                  <option value="Tajikistan">
                    Tajikistan
                  </option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">
                    Thailand
                  </option>
                  <option value="Togo">
                    Togo
                  </option>
                  <option value="Tokelau">
                    Tokelau
                  </option>
                  <option value="Tonga">
                    Tonga
                  </option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">
                    Tunisia
                  </option>
                  <option value="Turkey">
                    Turkey
                  </option>
                  <option value="Turkmenistan">
                    Turkmenistan
                  </option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">
                    Tuvalu
                  </option>
                  <option value="Uganda">
                    Uganda
                  </option>
                  <option value="Ukraine">
                    Ukraine
                  </option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">
                    United Kingdom
                  </option>
                  <option value="United States">
                    United States
                  </option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying
                    Islands
                  </option>
                  <option value="Uruguay">
                    Uruguay
                  </option>
                  <option value="Uzbekistan">
                    Uzbekistan
                  </option>
                  <option value="Vanuatu">
                    Vanuatu
                  </option>
                  <option value="Venezuela">
                    Venezuela
                  </option>
                  <option value="Vietnam">
                    Vietnam
                  </option>
                  <option value="Virgin Islands (British)">
                    Virgin Islands (British)
                  </option>
                  <option value="Virgin Islands (U.S.)">
                    Virgin Islands (U.S.)
                  </option>
                  <option value="Wallis and Futuna Islands">
                    Wallis and Futuna Islands
                  </option>
                  <option value="Western Sahara">
                    Western Sahara
                  </option>
                  <option value="Yemen">
                    Yemen
                  </option>
                  <option value="Yugoslavia">
                    Yugoslavia
                  </option>
                  <option value="Zambia">
                    Zambia
                  </option>
                  <option value="Zimbabwe">
                    Zimbabwe
                  </option>
                </select>

                <label for="certification" class="mt-8">
                  PLEASE SUBMIT THE FOLLOWING DOCUMENTS PENDING LOCATION OF YOUR AGENCY
                  <span class="ml-1 text-red-500">*</span>
                </label>
                <p class="text-sm">
                  (Expired Documents Not Accepted)
                </p>

                <ul class="list-disc pl-5 mb-4">
                  <li>Copy of IATA, CLIA, ARC, TIDS or True Number Certification</li>

                  <li>
                    Completed and signed
                    <a href="/pdfs/W-9.pdf" class="text-blue-500 hover:text-blue-700 underline" download>W-9 Form</a> (U.S. based agencies only)
                  </li>

                  <li>
                    Completed and signed
                    <a href="/pdfs/ACH.pdf" class="text-blue-500 hover:text-blue-700 underline" download>U.S. ACH Authorization Form</a> (U.S. based agencies only)
                  </li>

                  <li>
                    Completed and signed
                    <a href="/pdfs/W8BEN.pdf" class="text-blue-500 hover:text-blue-700 underline" download>W8BEN Form</a> (Agencies outside the U.S. only)
                  </li>

                  <li>
                    Completed and signed
                    <a href="/pdfs/WireTransfer.pdf" class="text-blue-500 hover:text-blue-700 underline" download>International Wire Transfer Form</a> (Agencies outside the U.S. only)
                  </li>
                </ul>

                <input
                  id="forms"
                  type="file"
                  name="forms[]"
                  multiple
                  @change="handleFileUpload"
                >
              </fieldset>

              <div class="w-2/5">
                <fieldset class="mb-6">
                  <label for="agency_contact">Agency Contact Name
                    <span class="ml-1 text-red-500">*</span>
                  </label>
                  <input
                    id="agency_contact" v-model="agencyContact" type="text" name="agency_contact_name"
                  >
                </fieldset>

                <fieldset class="mb-6">
                  <label for="phone">Phone
                    <span class="ml-1 text-red-500">*</span>
                  </label>
                  <input
                    id="phone" v-model="phone" type="text" name="phone_number"
                  >
                </fieldset>

                <fieldset>
                  <label for="email">Email
                    <span class="ml-1 text-red-500">*</span>
                  </label>
                  <input
                    id="email" v-model="email" type="email" name="email_address"
                  >
                </fieldset>

                <fieldset class="bg-blue-500 text-white px-4 pt-8 pb-6 mt-8 text-sm text-center">
                  <label for="accept">
                    <input
                      id="accept" v-model="agreeToTerms" type="checkbox" value="1"
                      name="accepted_privacy_policy"
                    > I have read and accepted the
                    <a class="text-white underline" href="/privacy" target="_blank">Privacy
                      Policy</a>
                  </label>

                  <p class="text-xs opacity-50">
                    By submitting this form, I confirm I would like to receive emails on promotions,
                    special offers and updates from Atlantis Paradise Island and its affiliated
                    brands.
                  </p>
                </fieldset>
              </div>
            </div>

            <div class="text-center">
              <p v-if="showError" class="mt-10 mb-4 text-xl text-red-500 font-bold">
                * All Fields Are Required *
              </p>
              <button class="btn bg-glueblue-700 text-white font-bold" @click.prevent="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
