<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Hide Five-Nine Chat',
        group: 'Five 9',
        layouts: ['Atlantisbahamas', 'Npiwff', 'NPIWFF'],
      },
      description: 'Hides the chat',
      tags: ['Five 9', 'Customer Support', 'Vendor'],
      templates: [{
        label: 'Hide Five  Chat',
        fields: [],
      }],
    })
    setTimeout(() => {
      this.hideFive9Chat()
    }, 1500)
  },
  methods: {
    hideFive9Chat() {
      const five9Frame = document.querySelector('.five9-frame')
      if (five9Frame)
        document.querySelector('.five9-frame').style.display = 'none'
    },

  },
}
</script>

<template>
  <div />
</template>
