<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Press Release Index', group: 'Press Releases', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGluePressReleasesPressReleaseIndex.jpg',
      description: 'Press Release Index system with filters',
      tags: ['Press Release', 'Index'],
      slots: [{ name: 'default' }],
      fields: { introduction: { type: 'wysiwyg', label: 'Introduction', enabled: false }, contactForm: { type: 'link', label: 'Contact Form', enabled: false }, imagesRequestForm: { type: 'link', label: 'Images Request Form', enabled: false } },
      templates: [{ label: 'Press Release Index', fields: { introduction: { value: '<h3>Welcome to the Atlantis Newsroom<br><\/h3><p>If you are seeking more information about Atlantis or have a media query, please use our contact form to reach our communications team.<\/p><p><\/p>' }, contactForm: { value: { text: 'Contact Form', target: '_blank', relationship: null, href: '\/new-page' } } } }],
    })
  },
}
</script>

<template>
  <div>
    <glue-press-releases-press-release-index :devise="devise">
      <slot />
    </glue-press-releases-press-release-index>
  </div>
</template>
