<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      events: [],
      expanded: null,
    }
  },
  computed: {
    chefEvents() {
      return this.events.filter((e) => {
        try {
          if (typeof e.chef === 'string') {
            const parsedChef = JSON.parse(e.chef)
            if (parsedChef.length > 0)
              return parsedChef.map(a => a.name).includes(this.fields.chef.value)

            if (typeof parsedChef === 'object')
              return parsedChef.name === this.fields.chef.value

            return parsedChef === this.fields.chef.value
          }

          return null
        }
        catch (error) {
          console.error('chef error', error)
          return e.chef === this.fields.chef.value
        }
      })
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Image With Color Copy Box', group: 'Cards', layouts: ['Npiwff', 'Atlantisbahamas'] },
      preview: 'SlicesWffImageWithColorCopyBox.jpg',
      description: 'Used for chefs along with their bio on the Wine and Food Festival pages',
      tags: ['Card', 'Media'],

      fields: {
        imagePosition: { type: 'select', label: 'Image Position', options: { left: 'Left', right: 'Right' } },
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 500, height: 600 } } },
        title: { type: 'text', label: 'Title' },
        bgcolor: { type: 'color', label: 'Background Color' },
        color: { type: 'color', label: 'Foreground Color' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link', enabled: false },
        chef: { type: 'select', label: 'WFF Chef (loads chef events)', options: { 'none': 'No Chef Data', 'Duff Goldman': 'Duff Goldman', 'Alex Guarnaschelli': 'Alex Guarnaschelli', 'JJ Johnson': 'JJ Johnson', 'Aaron Sanchez': 'Aaron Sanchez', 'Michael White': 'Michael White', 'Alon Shaya': 'Alon Shaya', 'Andrew Zimmern': 'Andrew Zimmern', 'Tony Abou-Ganim': 'Tony Abou-Ganim', 'Jose Andres': 'Jose Andres', 'Robert Irvine': 'Robert Irvine', 'Martha Stewart': 'Martha Stewart', 'Nobu Matsuhisa': 'Nobu Matsuhisa' } },
      },
      templates: [{
        label: 'Image With Color Copy Box',
        fields: {
          bgcolor: { value: 'rgba(167,230,241,1)' },
          color: { value: 'rgba(40,54,150,1)' },
          copy: { value: '<p>The highlights of the night included the delectable Lamb Albondigas, featuring lamb meatballs adorned with Manchego and Sherry &amp; Foie Gras Cream; and the Ora King Salmon, served with Smashed Sunchokes, Green Apple, Horseradish Cream, Smoked Trout Roe, and Red Veined Sorrel.<br><br>Guests also relished the Piri-Piri Roast Chicken accompanied by Grilled Corn Salad, Fava Beans, Field Peas, and Celery Root Puree. The Spiced Potatoes with Paprika A\u00EFoli, known as Patatas Bravas, and the exquisite Tropical Fruit Mille Feuille rounded out this spectacular culinary experience.&nbsp;&nbsp;<\/p>' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-table-takeovers-chef-jose-garces-cuisine.jpg' },
          imagePosition: { value: 'left' },
          link: { value: { text: 'Learn More', target: '_blank', relationship: null, href: '\/new-page' } },
          title: { value: 'an unforgettable evening' },
        },
      }],
    })
    this.getEvents()
  },

  methods: {
    async getEvents() {
      const { data } = await $voixFetch(`/api/atlantis/events/Atlantis%20Events/months/march`)
      this.events = data
    },

    getVenueName(venue) {
      if (venue) {
        if (venue === 'JokersWild')
          return 'Joker\'s Wild'

        if (venue === 'MarinaVillage')
          return 'Marina Village'

        if (venue === 'ConferencesAndEvents')
          return 'Conference & Event Center'

        return venue
      }
    },

    toggleSelectedEvent(eventId) {
      if (this.expanded === eventId) {
        this.expanded = null
        return
      }
      this.expanded = eventId
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="container relative grid grid-cols-1 lg:grid-cols-12 gap-2">
      <div
        class="relative lg:col-span-4 overflow-hidden min-h-[350px]"
        :class="{ 'lg:col-start-9': fields.imagePosition.value === 'right' }"
      >
        <VoixMedia :field="fields.image" class="absolute object-cover w-full h-full" />
      </div>
      <div
        class="lg:col-span-8 py-8 px-6"
        :class="{
          'lg:col-start-0 lg:row-start-1': fields.imagePosition.value === 'right',
        }"
        :style="{
          backgroundColor: fields.bgcolor.value,
          color: fields.color.value,
        }"
      >
        <div class="flex flex-col space-y-4">
          <div class="text-3xl font-bold font-wff-headline uppercase">
            {{ fields.title.value }}
          </div>
          <div class="text-sm font-sans2" v-html="fields.copy.value" />
          <div v-if="fields.link.enabled">
            <a :href="fields.link.value?.href" class="text-xl uppercase font-sans2 font-bold text-primary-500">
              {{ fields.link.value.text }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="fields.chef.value" class="container">
      <div class="my-6 grid md:grid-cols-2 gap-x-4 gap-y-8">
        <div v-for="event in chefEvents" :key="event.id" class="grid lg:grid-cols-2">
          <div />
          <div class="bg-zinc-100 p-6 font-sans2">
            <div class="text-glueblue-600 font-bold uppercase font-wff-headline text-xl">
              {{ event.title }}
            </div>
            <div class="text-glueblue-600 uppercase font-wff">
              <date-formatter :date="event.start_date" format="MMMM D, YYYY" />
            </div>
            <div class="text-glueblue-600 uppercase font-wff">
              <date-formatter :date="event.start_date" format="h a" />
            </div>
            <div class="text-glueblue-600 font-bold uppercase font-wff">
              {{ getVenueName(event.venue) }}
            </div>
            <div
              class="mt-3 text-sm"
              :class="{
                'line-clamp-4': expanded !== event.id,
              }"
              v-html="event.content"
            />
            <button
              class="mt-2 text-glueblue-600 font-bold uppercase text-xs flex items-center space-x-1"
              @click="toggleSelectedEvent(event.id)"
            >
              <span>{{ expanded !== event.id ? `LearnMore` : 'Close' }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
            <div v-if="event.booking_url" class="mt-6">
              <a
                :href="event.booking_url"
                target="_blank"
                class="border border-glueblue-600 pt-2.5 pb-2 px-6 uppercase text-glueblue-600 text-xs"
              >
                <span>Tickets</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
