<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Flat Card', group: 'Cards', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
      preview: 'SlicesGlueGenericCardsFlatCard.jpg',
      description: 'Flat Card with the ability to have 2 columns',
      tags: ['Card'],
      fields: {
        twoColumns: { type: 'checkbox', label: 'Two Columns' },
        backgroundColor: { type: 'select', label: 'Background Color', allowNull: false, options: { '#eee6d0': 'Gold', '#e0e0df': 'Gray', '#fff': 'White' } },
        textAlign: { type: 'select', label: 'Text Align', allowNull: false, options: { left: 'Left', center: 'Center', right: 'Right' } },
        column1Copy: { type: 'wysiwyg', label: 'Column 1 Copy' },
        column1Link: { type: 'link', label: 'Column One Link', enabled: false },
        column2Copy: { type: 'wysiwyg', label: 'Column 2 Copy' },
        column2Link: { type: 'link', label: 'Column Two Link', enabled: false },
      },
      templates: [{
        label: 'Flat Card',
        fields: {
          backgroundColor: { value: '#fff' },
          column1Copy: { value: '<p><strong>NAME: <\/strong>Management and Operation of Atlantis Logo Retail Stores<\/p><p><strong>RFP <\/strong>#: IHCL-RTLMG-023<\/p><p><strong>Issue<\/strong> <strong>Date: <\/strong>08.10.23<\/p><p><strong>Close\/Due Date:<\/strong> 09.15.23<br><\/p><p><strong>Contact: <\/strong>Vaughn Roberts<\/p><p><strong>Details:<\/strong>&nbsp; IHCL is seeking proposals from prospective bidders interested in managing and operating the Atlantis Logo Retail Stores at the Resort.<br><br><strong>Minimum Qualifications:<\/strong> <br>1. Attend the mandatory pre-submission conference. <br>2. Possess five (5) or more years of continuous experience within the last ten (10) years in ownership, management, or operation of touristic retail stores or similar retail in airports, cruise ports or resort environments. <br>3. Be financially capable of fulfilling the Agreement in the sole determination of Atlantis. <br>4. Submit a complete proposal.<\/p><p><br><\/p><p>Please address all inquiries regarding this RFP to: vaugh.roberts@atlantisparadise.com.<\/p><p><br><\/p><p>Required Documents: <a href="https:\/\/files.atlantisbahamas.com\/all\/rfp\/New%20Vendor%20Approval%20and%20Vendor%20Certification%20Form%20-%20Blank_Fillable.pdf" rel="noopener noreferrer nofollow">IHCL-Vendor Certification<\/a>, <a href="https:\/\/files.atlantisbahamas.com\/all\/rfp\/Standard%20MNDA.pdf" rel="noopener noreferrer nofollow">IHCL-Mutual Non-Disclosure Agreement<\/a><\/p>' },
          textAlign: { value: 'left' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="container px-8">
    <div
      class="p-8 md:p-12 md:mx-0 w-full"
      :style="`background-color:${fields.backgroundColor.value}`"
    >
      <div
        class="text-sm"
        :class="{
          'md:flex': fields.twoColumns.value,
          'text-left': fields.textAlign.value === 'left',
          'text-center': fields.textAlign.value === 'center',
          'text-right': fields.textAlign.value === 'right',
          'divide-gray-200 md:divide-x': fields.twoColumns.value,
        }"
      >
        <div
          :class="{
            'md:w-1/2 md:pr-12': fields.twoColumns.value,
            'md:w-4/5': !fields.twoColumns.value,
          }"
        >
          <div
            class="wywiwyg font-sans2 text-gray-600 w-full"
            v-html="fields.column1Copy.value"
          />
          <a
            v-if="fields.column1Link.enabled"
            :href="fields.column1Link.value?.href"
            class="mt-4 btn-ghost bt-sm"
          >{{ fields.column1Link.value.text }}</a>
        </div>
        <div
          :class="{
            'md:w-1/2 md:pl-12': fields.twoColumns.value,
            'md:w-4/5 mt-8': !fields.twoColumns.value,
          }"
        >
          <VoixWysiwyg
            class="wywiwyg font-sans2 text-gray-600 w-full"
            :field="fields.column2Copy"
          />
          <a
            v-if="fields.column2Link.enabled"
            :href="fields.column2Link.value?.href"
            class="mt-4 btn-ghost btn-sm"
          >{{ fields.column2Link.value.text }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
