<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Video With Description', group: 'Wff', layouts: ['Npiwff', 'Atlantisbahamas'] },
      description: 'Video with Description',
      tags: ['Video', 'Wistia', 'Vendor'],

      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link' },
        videoId: { type: 'text', label: 'Wistia ID' },
        image: { type: 'media', label: 'Image', instructions: '"Video ID must be empty for image to show"', breakpoints: { lg: { width: 800, height: 450 } } },
      },
      templates: [{
        label: 'Video With Description',
        fields: {
          description: { value: '<p>On October 1, Chef Garces designed a limited-edition menu for an exclusive beachside dinner at The Cove Beach, where a select group of guests had the unique opportunity to experience his exceptional cuisine in an intimate&nbsp;dining environment.&nbsp;<\/p>' },
          title: { value: 'EXCLUSIVE DINNER AT THE COVE BEACH' },
          videoId: { value: 'btrb605tyh' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center bg-red-500 text-white">
    <div class="container grid md:grid-cols-2 gap-8">
      <div class="py-12">
        <div
          class="uppercase text-2xl font-bold font-wff-headline"
          v-html="fields.title.value"
        />
        <div class="wysiwyg font-wff font-light" v-html="fields.description.value" />
        <a
          v-if="fields.link.value?.text"
          :href="fields.link.value?.href"
          class="inline-block mt-6 font-wff-headline font-bold uppercase px-8 pt-2 pb-3 border border-white"
        >
          {{ fields.link.value?.text }}
        </a>
      </div>
      <div class="border-l-8 border-white">
        <div
          class="relative text-white font-sans2 body overflow-hidden cursor-pointer w-full"
        >
          <glue-media-wistia
            v-if="fields.videoId.value"
            :video-id="fields.videoId.value"
            class="w-full h-full aspect-video relative z-30"
          />
          <VoixMedia v-else :field="fields.image" class="object-cover w-full h-full" />
        </div>
      </div>
    </div>
  </div>
</template>
