<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      origin: 'https%3A%2F%2Fatlantisbahamas.com%2F',
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Glue Inline Calendar', group: 'Booking', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
      preview: 'SlicesGlueBookingGlueInlineCalendar.jpg',
      description: 'Information Block',
      tags: ['Booking', 'Calendar'],
      fields: {
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 600, height: 600 } } },
        title: { type: 'text', label: 'Title', editorLabel: true, default: '"BOOK YOUR EXPERIENCE TODAY"' },
        copy: { type: 'wysiwyg', label: 'Introduction', enabled: true },
        buttonLabel: { type: 'text', label: 'Button Label', default: '"Search"' },
        mode: { type: 'select', label: 'Date Mode', options: { inline: 'Single Date', duo: 'Two Dates' } },
      },
      templates: [{
        label: 'Glue Inline Calendar',
        fields: {
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-aquaventure-power-tower-the-surge-waterslide.jpg' },
          copy: { value: '<p>Swim With The Dolphins, have Play Time With Sea Lions, <br>take a Leap of Faith, stroll on the white sand beaches, reserve a cabana, <BR>the options are plentiful. <br><br>Book your experiences and Atlantis Day Passes today!<br><\/p><p><br><\/p><p><strong>Tickets must be purchased in advance.<br><\/strong><\/p><p><br><\/p>' },
          mode: { value: 'duo' },
        },
      }],
    })

    const encodedPath = encodeURIComponent(this.currentPage.path)
    this.origin = `https%3A%2F%2Fatlantisbahamas.com${encodedPath}%2F`
  },
}
</script>

<template>
  <div>
    <div class="container mb-24 text-align-center">
      <div class="flex items-center justify-center bg-white">
        <BookingMiniInlineBooking :origin="origin" prefill-check-in="" prefill-check-out="" :devise="devise" />
      </div>
    </div>
  </div>
</template>
