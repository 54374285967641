<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Mmw Waves Divider', group: 'Music Making Waves', layouts: ['MusicMakingWaves'] },
      preview: 'SlicesMusicMakingWavesMmwWavesDivider.jpg',
      tags: ['FAQ'],
      templates: [{ label: 'Mmw Waves Divider', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="text-mmw-teal">
    <mmw-mmw-divider />
  </div>
</template>
