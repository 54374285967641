<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tab: 'tab1',
      hoverTab: 'tab1',
    }
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Careers Tabs',
        group: 'Careers',
        layouts: ['Atlantisbahamas'],
      },
      preview: 'SlicesCareersCareersTabs.jpg',
      description: 'Careers Tabs',
      tags: ['Careers', 'Navigation'],
      fields: {
        tab1Label: { label: 'Tab 1 Label', type: 'text' },
        tab1Title: { label: 'Tab 1 Title', type: 'text' },
        tab1Copy: { label: 'Tab 1 Copy', type: 'wysiwyg' },
        tab1Link: { label: 'Tab 1 Link', type: 'link', enabled: false },
        tab1Image: { label: 'Tab 1 Image', type: 'media', breakpoints: { lg: { width: 600, height: 400 }, md: { width: 400, height: 300 } } },
        tab2Label: { label: 'Tab 2 Label', type: 'text' },
        tab2Title: { label: 'Tab 2 Title', type: 'text' },
        tab2Copy: { label: 'Tab 2 Copy', type: 'wysiwyg' },
        tab2Link: { label: 'Tab 2 Link', type: 'link', enabled: false },
        tab2Image: { label: 'Tab 2 Image', type: 'media', breakpoints: { lg: { width: 600, height: 400 }, md: { width: 400, height: 300 } } },
        tab3Label: { label: 'Tab 3 Label', type: 'text' },
        tab3Title: { label: 'Tab 3 Title', type: 'text' },
        tab3Copy: { label: 'Tab 3 Copy', type: 'wysiwyg' },
        tab3Link: { label: 'Tab 3 Link', type: 'link', enabled: false },
        tab3Image: { label: 'Tab 3 Image', type: 'media', breakpoints: { lg: { width: 600, height: 400 }, md: { width: 400, height: 300 } } },
      },
      templates: [{
        label: 'Careers Tabs',
        fields: {
          tab1Label: { value: 'Our Mission' },
          tab1Title: { value: 'Our Mission' },
          tab1Copy: { value: '<p>We provide world class service and create lifelong memories by immersing our guests in authentic Bahamian culture. Team members are valued, and their success is celebrated. We are committed to responsible environmental stewardship, while producing exceptional results for our stakeholders.<\/p>' },
          tab1Link: { value: { text: 'APPLY NOW', target: '_self', relationship: null, href: '\/new-page' } },
          tab1Image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Careers\/screenshot-2023-07-14-131610.png' },
          tab2Label: { value: 'Our Vision' },
          tab2Title: { value: 'Our vision' },
          tab2Copy: { value: '<p><strong>TO BE THE MOST REMARKABLE DESTINATION RESORT IN THE WORLD.<br><br><\/strong><\/p><p>We are looking for remarkable people who can bring our vision, mission, and values to life.<br><\/p><p>Our ideal candidates are self-motivated people who thrive in environments where they are empowered to think and act independently. We are seeking passionate individuals of the highest integrity who can develop lasting relationships with our guests and want to enjoy fulfilling careers with us.<\/p><p><br><br><\/p>' },
          tab2Image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Careers\/screenshot-2023-07-14-131431.png' },
          tab3Label: { value: 'Core values' },
          tab3Title: { value: 'Core Values' },
          tab3Copy: { value: '<p><strong>WE ACT WITH INTEGRITY<\/strong><\/p><p>We earn trust by acting with integrity. It\u2019s the rock-solid foundation on which everything else we do is built on, including every single interaction with our guests, team members and managers.<br><br><\/p><p><strong>OUR PASSION IS OUR PURPOSE<\/strong><\/p><p>We are sincerely passionate about the work we do and truly care about creating incredible experiences for our guests, sharing the rich spirit of our Bahamian culture and helping Atlantis achieve incredible success.<br><br><\/p><p><strong>WE ARE COMMITTED TO DOING MORE<\/strong><\/p><p>We love what we do, and we\u2019re committed to making a positive difference in the world. That means being 100% focused on protecting our precious marine environment and making all our colleagues feel supported, included, accepted, and celebrated.<br><br><\/p><p><strong>WE ARE COURAGEOUS<\/strong><\/p><p>We challenge ourselves to do it better and bolder. We aren\u2019t afraid to take risks and think out of the box. We are empowered to act decisively, overcome our limitations, and conquer our fears.<\/p>' },
          tab3Image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Careers\/screenshot-2023-07-17-170409.jpg' },
        },
      }],
    })
  },
  methods: {
    setTab(tab) {
      this.tab = tab
    },
    setHoverTab(tab) {
      this.hoverTab = tab
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="hidden lg:flex divide-x divide-orange-300 w-full bg-orange-100 border-y border-zinc-300">
      <button
        class="relative flex justify-center items-center space-x-3 w-1/3 py-6 transition-colors duration-300"
        :class="{ 'bg-white': hoverTab === 'tab1' || tab === 'tab1', 'bg-tide-primary': tab !== 'tab1' && hoverTab !== 'tab1' }"
        @click="setTab('tab1')"
        @mouseenter="setHoverTab('tab1')"
        @mouseleave="setHoverTab('')"
      >
        <div class="headline-x text-18">
          <div class="flex justify-center items-center space-x-2">
            <div
              class="rounded-full w-6 h-6"
              :class="{ 'bg-tide-primary': hoverTab === 'tab1' || tab === 'tab1', 'bg-white': tab !== 'tab1' && hoverTab !== 'tab1' }"
              style="
                mask-image: url(/imgs/logos/dolphin-cay-sm.png);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
              "
            />
            <span
              class="font-tide-headline font-bold"
              :class="{ 'text-tide-primary': hoverTab === 'tab1' || tab === 'tab1', 'text-white': tab !== 'tab1' && hoverTab !== 'tab1' }"
            >
              {{ fields.tab1Label.value }}
            </span>
          </div>
        </div>
      </button>

      <button
        class="relative flex justify-center items-center space-x-3 w-1/3 py-6 transition-colors duration-300"
        :class="{ 'bg-white': hoverTab === 'tab2' || tab === 'tab2', 'bg-tide-primary': tab !== 'tab2' && hoverTab !== 'tab2' }"
        @click="setTab('tab2')"
        @mouseenter="setHoverTab('tab2')"
        @mouseleave="setHoverTab('')"
      >
        <div class="headline-x text-18">
          <div class="flex justify-center items-center space-x-2">
            <div
              class="rounded-full w-6 h-6"
              :class="{ 'bg-tide-primary': hoverTab === 'tab2' || tab === 'tab2', 'bg-white': tab !== 'tab2' && hoverTab !== 'tab2' }"
              style="
                mask-image: url(/imgs/logos/marine-adventures-md.png);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
              "
            />
            <span
              class="font-tide-headline font-bold"
              :class="{ 'text-tide-primary': hoverTab === 'tab2' || tab === 'tab2', 'text-white': tab !== 'tab2' && hoverTab !== 'tab2' }"
            >
              {{ fields.tab2Label.value }}
            </span>
          </div>
        </div>
      </button>

      <button
        class="relative flex justify-center items-center space-x-3 w-1/3 py-6 transition-colors duration-300"
        :class="{ 'bg-white': hoverTab === 'tab3' || tab === 'tab3', 'bg-tide-primary': tab !== 'tab3' && hoverTab !== 'tab3' }"
        @click="setTab('tab3')"
        @mouseenter="setHoverTab('tab3')"
        @mouseleave="setHoverTab('')"
      >
        <div class="headline-x text-18">
          <div class="flex justify-center items-center space-x-2">
            <div
              class="rounded-full w-6 h-6"
              :class="{ 'bg-tide-primary': hoverTab === 'tab3' || tab === 'tab3', 'bg-white': tab !== 'tab3' && hoverTab !== 'tab3' }"
              style="
                mask-image: url(/imgs/logos/all-adventures-md.png);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
              "
            />
            <span
              class="font-tide-headline font-bold"
              :class="{ 'text-tide-primary': hoverTab === 'tab3' || tab === 'tab3', 'text-white': tab !== 'tab3' && hoverTab !== 'tab3' }"
            >
              {{ fields.tab3Label.value }}
            </span>
          </div>
        </div>
      </button>
    </div>

    <div class="flex justify-center">
      <div class="container py-12">
        <div class="lg:hidden flex justify-center mb-8">
          <select v-model="tab" class="py-3 px-8 bg-white font-sans2 font-bold uppercase tracking-widest">
            <option value="tab1">
              {{ fields.tab1Label.value }}
            </option>
            <option value="tab2">
              {{ fields.tab2Label.value }}
            </option>
            <option value="tab3">
              {{ fields.tab3Label.value }}
            </option>
          </select>
        </div>
        <div v-if="tab === 'tab1'" class="flex flex-col-reverse lg:grid grid-cols-12 gap-8">
          <div class="w-full col-span-5 text-center">
            <div class="font-sans text-2xl font-light uppercase text-glueblue-600">
              {{ fields.tab1Title.value }}
            </div>
            <div class="font-sans2" v-html="fields.tab1Copy.value" />
            <VoixLink
              :field="fields.tab1Link"
              class="mt-8 btn-blue btn-primary"
            />
          </div>
          <div class="col-span-7">
            <div class="aspect-video">
              <VoixMedia :field="fields.tab1Image" class="w-full h-full object-cover" />
            </div>
          </div>
        </div>
        <div v-if="tab === 'tab2'" class="grid grid-cols-12 gap-8">
          <div class="col-span-5 text-center">
            <div class="font-sans text-2xl font-light uppercase text-glueblue-600">
              {{ fields.tab2Title.value }}
            </div>
            <div class="font-sans2" v-html="fields.tab2Copy.value" />
            <VoixLink
              :field="fields.tab2Link"
              class="mt-8 btn-blue btn-primary"
            />
          </div>
          <div class="col-span-7">
            <div class="aspect-video">
              <VoixMedia :field="fields.tab2Image" class="w-full h-full object-cover" />
            </div>
          </div>
        </div>
        <div v-if="tab === 'tab3'" class="grid grid-cols-12 gap-8">
          <div class="col-span-5 text-center">
            <div class="font-sans text-2xl font-light uppercase text-glueblue-600">
              {{ fields.tab3Title.value }}
            </div>
            <div class="font-sans2" v-html="fields.tab3Copy.value" />
            <VoixLink
              :field="fields.tab3Link"
              class="mt-8 btn-blue btn-primary"
            />
          </div>
          <div class="col-span-7">
            <div class="aspect-video">
              <VoixMedia :field="fields.tab3Image" class="w-full h-full object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
