<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    channelClass() {
      if (this.fields.channelId.value) {
        return `wistia_async_${this.fields.channelId.value}`
      }

      return ''
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Wistia Channel', group: 'Videos', layouts: ['Atlantisbahamas', 'NPIWFF'] },
      preview: 'SlicesVideoWistiaChannel.jpg',
      description: 'Wistia Channel',
      tags: ['Videos', 'Wistia', 'Vendor'],

      fields: { channelId: { type: 'text', label: 'Channel ID' } },
      templates: [{ label: 'Wistia Channel', fields: { channelId: { value: 'v0ry0bc5zd' } } }],
    })
    if (this.fields.channelId.value) {
      const link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('src', `https://fast.wistia.com/embed/channel/project/${this.fields.channelId.value}/font.css/`)
      this.$refs.scripttarget.appendChild(link)

      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('src', 'https://fast.wistia.com/assets/external/channel.js')
      this.$refs.scripttarget.appendChild(script)
    }
  },
}
</script>

<template>
  <div
    ref="scripttarget" class="wistia_channel mode=inline"
    :class="channelClass"
    style="min-height:100vh;position:relative;width:100%;"
  />
</template>
