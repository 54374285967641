<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Introduction', group: 'Copy', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesTwentyFifthAnniversaryIntroduction.jpg',
      description: '25th Anniversary Introduction',
      tags: ['Copy', '25th Anniversary'],
      fields: {
        copy: {
          type: 'wysiwyg',
          label: 'Copy',
          default: '"Join us for The Royal 25th Anniversary - a year-long celebration honoring the iconic Royal at Atlantis. Discover incredible adventures, celebrity concerts, unique experiences, and oceans of legendary moments."',
        },
        link: { type: 'link', label: 'Link', enabled: false },
      },
      templates: [{
        label: 'Introduction',
        fields: { link: { value: { text: 'Celebration Offers', target: '_self', relationship: null, href: '\/new-page' } } },
      }],
    })
  },
}
</script>

<template>
  <div class="bg-glueblue-700 text-glueblue-100 p-8 lg:p-16">
    <div class="flex justify-center items-center space-x-4 font-serif italic text-2xl font-light">
      <span>The Rebirth</span>
      <img src="/imgs/twenty-fifth-anniversary/twenty-fifth-anniversary-royal-logo.png" alt="25th Anniversary Logo" class="w-48">
      <span>of a legend</span>
    </div>
    <div class="mt-12 flex flex-col items-center text-center space-y-8">
      <div class="max-w-[700px] font-sans text-xl font-light" v-html="fields.copy.value" />

      <a v-if="fields.link.enabled" :href="fields.link.value?.href" class="btn-ghost">{{ fields.link.value?.text }}</a>
    </div>
  </div>
</template>
