<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Tombstone Slide', group: 'Sliders', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesTwentyFifthAnniversaryTombstoneSlide.jpg',
      description: '25th Anniversary Tombstone Slide',
      tags: ['Sliders', '25th Anniversary'],

      fields: {
        topTitle: { type: 'text', label: 'Top Title' },
        topTitleLink: { type: 'link', label: 'Top Title Link (only uses the URL)', enabled: false },
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 500, height: 600 } } },
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy' },
      },
      templates: [{
        label: 'Tombstone Slide',
        fields: {
          copy: { value: '<p>Join us this June for a special World Ocean Month celebration event and be a part of this global movement that seeks to educate and raise awareness about the importance of protecting our oceans.<\/p>' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/Events\/2023\/25th%20Anniversary\/world-oceans-month.png' },
          title: { value: 'A Global Celebration' },
          topTitle: { value: 'Just add water' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex flex-col items-center space-y-8 py-8 lg:py-16 max-w-[300px] mx-auto">
    <VoixLink v-if="fields.topTitleLink.enabled" :field="fields.topTitleLink" class="block btn-ghost !border-glueblue-600 !text-glueblue-600 btn-xs" />
    <div v-else class="block btn-ghost !border-glueblue-600 !text-glueblue-600 btn-xs">
      {{ fields.topTitle.value }}
    </div>

    <div class="h-[100px] border-r border-glueblue-600" />

    <div class="w-full aspect-square rounded-t-full overflow-hidden">
      <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
    </div>

    <div class="text-center">
      <div class="font-sans2 font-thin text-xl uppercase">
        {{ fields.title.value }}
      </div>
      <div class="mt-4 text-xs font-sans2 text-center" v-html="fields.copy.value" />
    </div>
  </div>
</template>
