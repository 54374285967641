<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lightboxOpen: false,
    }
  },
  computed: {
    linkHref() {
      if (this.fields.videoId.enabled || this.fields.wistiaVideoId.enabled)
        return 'javascript:void(0);'

      return this.fields.link.value?.href
    },
    linkTarget() {
      if (this.fields.videoId.enabled || this.fields.wistiaVideoId.enabled)
        return '_self'

      return this.fields.link.value?.target
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Vertical Card On Top Of Image', group: 'Cards', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesCardsVerticalCardOnTopOfImage.jpg',
      description: 'Contains the content to a max width',
      tags: ['Card'],
      fields: { image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 480, height: 480 }, sm: { width: 360, height: 360 } } }, title: { type: 'text', label: 'Title', editorLabel: true }, copy: { type: 'wysiwyg', label: 'Introduction', enabled: true }, link: { type: 'link', label: 'Link', enabled: true }, videoId: { type: 'text', label: 'YouTube Video ID', enabled: false }, wistiaVideoId: { type: 'text', label: 'Wistia Video ID', enabled: false } },
      templates: [{ label: 'Vertical Card On Top Of Image', fields: { copy: { value: '<p>Leader Of The Year 2018<\/p>' }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/styled\/fd860059e9b82650f1d59f58eb92c3e5' }, title: { value: 'Rehana Dorsett, Director of VIP Services' }, wistiaVideoId: { value: 'iqnpot401s' } } }],
    })
  },
  methods: {
    openLightbox() {
      if (this.fields.videoId.enabled || this.fields.wistiaVideoId.enabled)
        this.lightboxOpen = true
    },
    closeLightbox() {
      this.lightboxOpen = false
    },

  },
}
</script>

<template>
  <div class="mb-12 flex flex-col justify-stretch items-center md:px-4">
    <div class="w-full bg-cover bg-center" style="height:320px;">
      <a
        class="w-full"
        :href="linkHref"
        :target="linkTarget"
        @click="openLightbox"
      >
        <VoixMedia :field="fields.image" lazy="{image: fields.image, $voix.breakpoint: $voix.breakpoint}" :alt="fields.image.value?.[0]?.description" class="w-full" />
      </a>
    </div>
    <div class="relative z-10 w-full md:w-4/5 bg-white flex-grow shadow -mt-16 p-10 py-16 animation-ease-up">
      <h3 class="text-blue-800 mb-4 uppercase">
        {{ fields.title.value }}
      </h3>
      <div class="font-serif text-sm leading-loose mb-8" v-html="fields.copy.value" />
      <a
        v-if="fields.videoId.enabled || fields.wistiaVideoId.enabled"
        class="cursor-pointer btn btn-link right-arrow text-black"
        @click="openLightbox"
      >Watch Now</a>
      <a
        v-if="fields.link.enabled"
        class="btn btn-link right-arrow text-black"
        :href="fields.link.value?.href"
        :target="fields.link.value?.target"
      >{{ fields.link.value?.text }}</a>
    </div>

    <Modal v-if="lightboxOpen" modal-classes="w-full" @close="closeLightbox">
      <Youtube v-if="fields.videoId.enabled" ref="youtube" :video-id="fields.videoId.value" class="w-full" />
      <iframe
        v-if="fields.wistiaVideoId.enabled"
        :src="`https://fast.wistia.net/embed/iframe/${fields.wistiaVideoId.value}?playerColor=254297`"
        allow="autoplay; fullscreen"
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        name="wistia_embed"
        msallowfullscreen
        class="w-full h-full wistia_embed"
      />
    </Modal>
  </div>
</template>
