<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      deprecated: true,
      name: { label: 'Slider Image', group: 'Sliders', layouts: ['Atlantisbahamas', 'NPIWFF'] },
      preview: 'SlicesSlidersSliderImage.jpg',
      description: 'Image for Slider',
      fields: {
        link: { label: 'Link', type: 'link', enabled: false },
        image: { label: 'Image', type: 'media', editorLabel: true, breakpoints: { lg: { width: 1600, height: 750 }, md: { width: 800, height: 375 }, sm: { width: 600, height: 600 } } },
        mobileImage: { label: 'Mobile Image', type: 'media', editorLabel: true, breakpoints: { sm: { width: 600, height: 600 } } },
      },
      templates: [{
        label: 'Slider Image',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/slim_heros/newisland_thin.jpg' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="w-full">
    <div>
      <a
        v-if="fields.link.enabled"
        :href="fields.link.value?.href"
        :target="fields.link.value?.target"
        class="block w-full h-full object-cover"
      >
        <VoixMedia :field="fields.image" class="w-full h-full object-cover " :class="{ 'hidden md:block': fields.mobileImage.value?.[0]?.url }" />
        <VoixMedia v-if="fields.mobileImage.value?.[0]?.url" :field="fields.image" class="block md:hidden  w-full" />
      </a>
      <template v-else>
        <VoixMedia :field="fields.image" class="w-full h-full object-cover" :class="{ 'hidden md:block': fields.mobileImage.value?.[0]?.url }" />
        <VoixMedia v-if="fields.mobileImage.value?.[0]?.url" :field="fields.image" class="block md:hidden w-full" />
      </template>
    </div>
  </div>
</template>
