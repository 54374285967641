<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
    }
  },

  computed: {
    breakpoint() {
      if (this.fields.size === 'large')
        return 'lg'
      else if (this.fields.size === 'medium')
        return 'md'
      else if (this.fields.size === 'small')
        return 'sm'
      else if (this.fields.size === 'xtrasmall')
        return 'default'

      return 'lg'
    },
    maxWidth() {
      if (this.fields.size === 'large')
        return 'max-w-5xl'
      else if (this.fields.size === 'medium')
        return 'max-w-4xl'
      else if (this.fields.size === 'small')
        return 'max-w-3xl'
      else if (this.fields.size === 'xtrasmall')
        return 'max-w-2xl'

      return 'max-w-4xl'
    },
  },

  mounted() {
    defineSlice({

      name: { label: 'Generic Image', group: 'Images', layouts: ['NPIWFF'] },
      preview: 'SlicesGlobalGenericImage.jpg',
      description: 'Generic Image',
      tags: ['Image'],
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            lg: { width: 1300 },
            md: { width: 900 },
            sm: { width: 700 },
            default: { width: 400 },
          },
        },
        size: {
          type: 'select',
          label: 'Size',
          options: {
            large: 'Large',
            medium: 'Medium',
            small: 'Small',
            xtrasmall: 'Extra Small',
          },
          default: 'medium',
        },
      },
      templates: [{ label: 'Generic Image', fields: { image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/NPIWFF\/calendar-events-graphic-v2.png' } } }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center ">
    <VoixMedia :field="fields.image" :breakpoint="breakpoint" :class="[maxWidth]" />
  </div>
</template>
