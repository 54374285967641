<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: {
        label: 'Toddlers Aka',
        group: 'Family',
        layouts: ['Atlantisbahamas'],
      },
      preview: 'SlicesFamilyToddlersAka.jpg',
      description: 'Toddlers AKA Widget',
      tags: ['Navigation'],
      fields: {
        victorianDollhouseImage: { type: 'media', label: 'Dollhouse Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        groceryStoreImage: { type: 'media', label: 'Grocery Store Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        legoConstructionImage: { type: 'media', label: 'Lego Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        wizardologyImage: { type: 'media', label: 'Wizardology Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        eveningProgrammingImage: { type: 'media', label: 'Evening Programming Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
      },

      templates: [{ label: 'Toddlers Aka', fields: { eveningProgrammingImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Preteens\/Atlantis_Kids_Adventures\/Hero\/Preteens_AtlantisKidsAdventures_PerformanceRoom.jpg' }, groceryStoreImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Toddlers\/Atlantis_Kids_Adventures\/Gallery\/toddlers_aka_grocerystore_booming.jpg' }, legoConstructionImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Toddlers\/Atlantis_Kids_Adventures\/Gallery\/toddlers_aka_legoroom.jpg' }, victorianDollhouseImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/kids\/atlantis-aka-imagination-station-tea-party.jpeg' }, wizardologyImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Toddlers\/Atlantis_Kids_Adventures\/Gallery\/toddlers_aka_wizardologyreading.jpg' } } }],
    })
  },
}
</script>

<template>
  <div>
    <glue-family-toddlers-aka :devise="devise" />
  </div>
</template>
