<script>
import { getCurrentInstance } from 'vue'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    alignment() {
      if (this.fields.align.value === 'null')
        return 'text-center'

      return `text-${this.fields.align.value}`
    },
    noPaddingIfChild() {
      const instance = getCurrentInstance()
      if (instance.parent && instance.parent.attrs?.slice) {
        return {
          padding: 0,
        }
      }
      return {}
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Container', group: 'Containers', layouts: ['Atlantisbahamas', 'Summer2024', 'NPIWFF', 'AtlantisMobileApp'] },
      preview: 'SlicesContainersContainer.jpg',
      slots: [{ name: 'default' }],
      description: 'Contains the content to a max width',
      tags: ['Container'],
      fields: { align: { type: 'select', label: 'Alignment', options: { left: 'Left', center: 'Center', right: 'Right' } } },
      templates: [{ label: 'Container', fields: { align: { value: 'center' } } }],
    })
  },
}
</script>

<template>
  <div class="px-4">
    <div class="container mb-24 max-w-[1120px]" :style="noPaddingIfChild" :class="[alignment]">
      <slot />
    </div>
  </div>
</template>

<style>

</style>
