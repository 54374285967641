<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Navigation Gallery Link', group: 'Galleries', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
      preview: 'SlicesGlueGalleriesNavigationGalleryLink.jpg',
      description: 'Links to go in the navigation gallery',
      fields: {
        link: { label: 'Link', type: 'link' },
      },
      templates: [
        {
          label: 'Navigation Gallery Link',
          fields: {
            link: {
              value: {
                text: 'DOLPHIN CAY & MARINE ADVENTURES',
                target: '_self',
                relationship: null,
                href: '\/new-page',
              },
            },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <a
    :href="fields.link.value?.href"
    :target="fields.link.value?.target"
    class="font-sans2 uppercase font-bold text-zinc-500"
  >
    {{ fields.link.value?.text }}
  </a>
</template>
