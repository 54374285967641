<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiper: null,
    }
  },
  computed: {
    activeIndex() {
      if (this.swiper) {
        const currentIndex = Number.parseInt(this.swiper.activeIndex) - 1
        if (currentIndex < 0)
          return this.slice.elements.length - 1
        else if (currentIndex >= this.slice.elements.length)
          return 0
        else
          return currentIndex
      }
      return null
    },

    autoplaySettings() {
      // If there is more than one slide, autoplay
      if (this.slice.elements.length > 1) {
        return {
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }
      }
      else {
        return false
      }
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Mmw Slider Hero', group: 'Music Making Waves', layouts: ['MusicMakingWaves'] },
      preview: 'SlicesMusicMakingWavesMmwSliderHero.jpg',
      description: 'Music Making Waves Hero Slider',
      tags: ['Hero'],

      slots: [{ name: 'default' }],
      fields: { showLogo: { type: 'checkbox', label: 'Show Logo?', default: true }, logoText: { type: 'text', label: 'Text instead of Logo', enabled: false }, showArrows: { type: 'checkbox', label: 'Show Arrows?' }, showDots: { type: 'checkbox', label: 'Show Dots?' }, textUnderneath: { type: 'text', label: 'Text Underneath Signup Form', default: '"Upcoming Shows Upcoming Shows Upcoming Shows"' } },
      templates: [{ label: 'Mmw Slider Hero', fields: { showDots: { value: true } } }],
    })

    this.updateOnInit()
  },

  methods: {
    updateOnInit() {
      setTimeout(() => {
        if (this.$refs.mySwiper?.$el?.swiper) {
          this.$refs.mySwiper.$el.swiper.update()
          this.swiper = this.$refs.mySwiper.$el.swiper
        }
        else {
          this.updateOnInit()
        }
      }, 1000)
    },

    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index + 1)
    },
  },
}
</script>

<template>
  <div>
    <div class="relative -mb-8">
      <div class="relative w-full h-screen overflow-hidden">
        <Swiper
          ref="mySwiper"
          :slides-per-view="1"
          :loop="slice.elements.length > 1"
          :centered-slides="true"
          :autoplay="autoplaySettings"
          style="position: absolute !important"
          class="absolute z-10 inset-0"
        >
          <SwiperSlide v-for="(slice, key) in slice.elements" :key="key">
            <VoixSlice :slice="slice" :slice-index="key" class="h-full" />
          </SwiperSlide>
        </Swiper>

        <div
          v-if="fields.showLogo.value"
          class="absolute z-50 top-0 left-0 right-0 lg:h-1/4 py-12 px-8 lg:px-32 bg-gradient-to-b from-mmw-blue/70 to-mmw-blue/0 pointer-events-none"
        >
          <glue-svgs-music-making-waves-logo
            class="w-full hidden lg:block"
          />
        </div>

        <div
          v-if="fields.logoText.enabled"
          class="hidden lg:block absolute z-50 top-0 left-0 right-0 py-12 px-8 lg:px-32 bg-gradient-to-b from-mmw-blue/70 to-mmw-blue/0 pointer-events-none"
        >
          <svg
            viewBox="0 0 300 50"
            shape-rendering="geometricPrecision"
            class="absolute  z-50 top-0 left-0 bottom-0 right-0 pointer-events-none"
          >
            <path
              id="mmw-text-curve2"
              transform="matrix(1.24699 0 0 0.957918-28.898684-115.058449)"
              d="M23.174752,120.113046c0,0,27.202072,17.619083,64.649081,17.619083s62.176166-17.619083,105.982101-17.619083c36.033914,0,52.284504,5.285725,69.948187,17.619083c0,21.475551,0,46.514379,0,65.895373-121.463286,0-181.935941,0-240.579369,0c0-29.24768,0-83.514456,0-83.514456Z"
              fill="none"
              stroke-width="0.6"
            />
            <text
              x="0"
              y="200"
              class="font-mmw-headline font-bold uppercase text-[2.5em] translate-y-6 tracking-tight"
              fill="#0DC6C8"
            >
              <textPath xlink:href="#mmw-text-curve2">
                {{ fields.logoText.value }}
              </textPath>
            </text>
          </svg>
        </div>

        <div class="absolute z-50 left-0 bottom-0 right-0 pointer-events-none">
          <svg
            viewBox="0 0 300 50"
            shape-rendering="geometricPrecision"
            class=""
          >
            <path
              id="mmw-text-curve"
              transform="matrix(1.24699 0 0 0.957918-28.898684-115.058449)"
              d="M23.174752,120.113046c0,0,27.202072,17.619083,64.649081,17.619083s62.176166-17.619083,105.982101-17.619083c36.033914,0,52.284504,5.285725,69.948187,17.619083c0,21.475551,0,46.514379,0,65.895373-121.463286,0-181.935941,0-240.579369,0c0-29.24768,0-83.514456,0-83.514456Z"
              fill="#fff"
              stroke-width="0.6"
            />
            <text
              x="0"
              class="font-mmw-headline font-bold uppercase text-24 translate-y-6 tracking-tight"
              fill="#0347D4"
            >
              <textPath xlink:href="#mmw-text-curve">
                {{ fields.textUnderneath.value }}
              </textPath>
            </text>
          </svg>
          <div class="bg-white h-24" />
        </div>
      </div>
      <div
        v-if="fields.showDots.value && slice.elements.length > 1"
        class="absolute z-50 bottom-0 mb-[300px] pr-12 right-0 flex items-center justify-end pointer-events-none space-x-2"
      >
        <button
          v-for="(slice, key) in slice.elements"
          :key="key"
          class="pointer-events-auto w-2 h-2 rounded-full"
          :class="{
            'bg-gray-500': activeIndex !== key,
            'bg-white': activeIndex === key,
          }"
          @click="goToSlide(key)"
        />
      </div>
      <div
        v-if="fields.showArrows.value && slice.elements.length > 1"
        class="absolute z-20 inset-0 flex items-center justify-between px-8 lg:ml-0 pointer-events-none"
      >
        <button
          class="rounded-full bg-white text-white w-20 h-20 flex justify-center items-center pointer-events-auto"
          @click="prev"
        >
          <svg
            class="w-24 h-24"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="rounded-full bg-white text-white w-20 h-20 flex justify-center items-center pointer-events-auto"
          @click="next"
        >
          <svg
            class="w-24 h-24 transform rotate-180"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}
.swiper-wrapper {
  @apply items-stretch;
}
</style>
