<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'White Card', group: 'Containers', layouts: ['Atlantisbahamas'] },
      SlicesGlueContainersWhiteCardpreview: 'SlicesGlueContainersWhiteCard.jpg',
      description: 'White card',
      tags: ['Container', 'Card'],
      fields: {
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        border: { type: 'select', label: 'Border', options: { none: 'None', top: 'Top', bottom: 'Bottom', left: 'Left', right: 'Right', all: 'All' } },
        borderColor: { type: 'select', label: 'Border color', options: { coral: 'Coral' } },
        alignText: { type: 'select', label: 'Align Text', options: { 'text-left': 'Text Left', 'text-center': 'Text Center', 'text-right': 'Text Right' } },
      },
      templates: [{
        label: 'White Card',
        fields: {
          title: { value: 'SPECIAL DIETARY NEEDS' },
          copy: { value: '<p>Our Culinary Team will arrange meals to accommodate most manageable food allergies or dietary restrictions. Many guests with kosher or special dietary needs choose to bring their own food with them. We do not accept raw products or products shipped with an external carrier. We will only accept food items shipped directly from the guests or our Certified Vendors. These can be heated and served in the guest suite or in our selected restaurants (prior reservations strongly recommended).<\/p><p>Please see below list of restaurants that can best manage meal preparation for the most common allergies, with possible limited menu selections according to guest single or multiple allergies.<\/p><p>\u2022 <strong>Breakfast<\/strong> \u2013 Murray\u2019s Deli<br>\u2022 <strong>Lunch<\/strong> - Silan, Shark Bites, Lagoon Bar, Fresca, Murray\u2019s Deli<br>\u2022 <strong>Dinner<\/strong> \u2013 Seafire, Carmines, Silan, Nobu, Fish, Caf\u00E9 Martinique, Bimini Road<br><\/p><p>For guest-brought meals that need storage, upon check-in, please notify the Food &amp; Beverage manager or chef by dialing extension #22 from the room phone for food pickup and inspection. The front office team will not accept any food items.&nbsp; <strong><br><em><u>NOTE<\/u>: Room Service is available at The Royal, The Coral and Cove\/Reef.<\/em><br><\/strong><\/p><p><strong>Service charge in all outlets (for halal and allergy meals)&nbsp;<br><\/strong>Reflects restaurant current menu pricing plus <strong>10% VAT<\/strong> and <strong>15%<\/strong> gratuity.<br><\/p><p><strong>Service Charge in room service (for halal and allergy meals)&nbsp;<br><\/strong>Reflects Room Service current menu pricing plus <strong>10% VAT<\/strong> and <strong>19%<\/strong> gratuity and Room Service delivery charge.<\/p>' },
          border: { value: 'top' },
          borderColor: { value: 'coral' },
          alignText: { value: 'text-center' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center my-8" :class="[fields.alignText.value]">
    <div
      class="bg-white p-10 max-w-3xl w-full  border-red-300" :class="{
        'border-t-4': fields.border.value === 'top' || fields.border.value === 'all',
        'border-b-4': fields.border.value === 'bottom' || fields.border.value === 'all',
        'border-l-4': fields.border.value === 'left' || fields.border.value === 'all',
        'border-r-4': fields.border.value === 'right' || fields.border.value === 'all',
      }"
    >
      <div class="text-red-300 uppercase font-sans2 text-2xl font-light">
        {{ fields.title.value }}
      </div>
      <div
        class="font-sans2 mt-4 prose" :class="{ 'mx-auto': fields.alignText.value === 'text-center', 'float-right': fields.alignText.value === 'text-right' }"
        v-html="fields.copy.value"
      />
    </div>
  </div>
</template>
