<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Mmw Event Card', group: 'Music Making Waves', layouts: ['MusicMakingWaves'] },
      preview: 'SlicesMusicMakingWavesMmwEventCard.jpg',
      description: 'Music Making Waves Event Card',
      tags: ['Event', 'Card'],

      fields: { title: { type: 'text', label: 'Title' }, image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 930, height: 500 }, sm: { width: 550, height: 400 } } }, mode: { type: 'select', label: 'Mode', options: { 'simple': 'Simple', 'complex': 'Complex', 'complex-right': 'Complex (Right)' } }, color: { type: 'select', label: 'Color', options: { blue: 'Blue', orange: 'Orange', teal: 'Teal' } }, date: { type: 'datetime', label: 'Date' }, getTicketsLink: { type: 'link', label: 'Get Tickets Link', enabled: false }, roomAndTicketsLinks: { type: 'link', label: 'Room and Tickets Links', enabled: false }, moreInfoContent: { type: 'wysiwyg', label: 'More Info Content', enabled: false }, moreInfoImage: { type: 'media', label: 'More Info Popup Image (More Info Content Required)', breakpoints: { lg: { width: 500, height: 550 } } }, bawImage: { type: 'media', label: 'Black and White Image (Complex only)', breakpoints: { lg: { width: 185, height: 550 } } } },
      templates: [{ label: 'Mmw Event Card', fields: { bawImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/nightlife\/atlantis-live-pentatonix.jpeg' }, color: { value: 'teal' }, date: { value: '2024-04-27' }, getTicketsLink: { value: { text: 'Get Tickets', target: '_self', relationship: null, href: '\/new-page' } }, image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/Janet%20Jackson\/janet-j.jpg' }, mode: { value: 'complex-right' }, moreInfoContent: { value: '<p>You\'re invited! 5x GRAMMY\u00AE Award-winning superstar&nbsp;Janet Jackson will take the stage at Casuarina Beach on Saturday, April 27th.&nbsp;<br><br>Jam out to one of the biggest names in music history and sing along as she performs top hits like Rhythm Nation and That\'s the Way Love Goes.&nbsp;<br><br><\/p><p>Concert Village Opens: 6:30pm | Doors Open 8:00pm | Showtime 9:00pm<\/p><p><br><u>Tickets starting at $199.00 + VAT<\/u><\/p><p>Diamond: $399++ <br>Platinum: $349++ <br>Gold: $299++ <br>Silver: $249++ <br>Bronze: $199++ <br><br><\/p>' }, moreInfoImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/MMW\/Janet%20Jackson\/janet-jackson-seating-chart-1.png' }, roomAndTicketsLinks: { value: { text: 'GET ROOM & TICKETS', target: '_self', relationship: null, href: '\/new-page' } }, title: { value: 'janet jackson live at atlantis' } } }],
    })
  },
}
</script>

<template>
  <div class="container mx-auto">
    <mmw-mmw-event-card :devise="devise" />
  </div>
</template>
