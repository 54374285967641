<script>
import { resolveFieldsArray } from '@voix/composables/useField'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiper: null,
      currentSlide: 0,
      showHotelInfo: true,
    }
  },
  computed: {
    hotelSlices() {
      if (this.slice.elements[0])
        return this.slice.elements[0].elements

      return []
    },

    cardStyles() {
      const styles = {}
      if (this.$voix.breakpoint.value === 'lg' || this.$voix.breakpoint.isGreaterThan('lg')) {
        styles.height = '250px'
        styles.width = '976px'
      }
      else if (this.$voix.breakpoint.value === 'md') {
        styles.height = 'auto'
        styles.width = '100%'
      }
      else {
        styles.height = 'auto'
      }

      return styles
    },

    currentElement() {
      return this.$refs[`hotelSlide-${this.currentSlide}`]
    },

    currentHotel() {
      return this.hotelSlices[this.currentSlide]
    },

    currentHotelFields() {
      return resolveFieldsArray(this.currentHotel)
    },

    hotelImages() {
      const images = []
      this.slice.elements[0].elements.map((slice) => {
        if (slice.logo && slice.magazineImage) {
          images.push(slice.logo.url)
          images.push(slice.magazineImage.url)
        }
        return slice
      })
      return images
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Hotel Index', group: 'Hotels', layouts: ['Atlantisbahamas', 'AtlantisMobileApp'] },
      preview: 'SlicesGlueHotelsHotelIndex.jpg',
      description: 'List of hotels',
      tags: ['Hotels'],
      fields: {
        displayTitleCard: { type: 'checkbox', label: 'Display Title Card?', default: true },
        titleCardPosition: { type: 'select', label: 'Title Card Position', options: { 'top-left': 'Top Left', 'top-right': 'Top Right', 'bottom-left': 'Bottom Left', 'bottom-right': 'Bottom Right' } },
        subTitle: { type: 'text', label: 'Sub Title' },
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Description' },
        wide: { type: 'checkbox', label: 'Wide?' },
        slider: { type: 'checkbox', label: 'Slider?', default: true },
        hotelCardPosition: { type: 'select', label: 'Hotel Card Position', options: { 'top-left': 'Top Left', 'top-right': 'Top Right', 'bottom-left': 'Bottom Left', 'bottom-right': 'Bottom Right' } },
      },
      templates: [{
        label: 'Hotel Index',
        fields: {
          hotelCardPosition: { value: 'top-left' },
          titleCardPosition: { value: 'bottom-right' },
        },
      }],
    })
    this.preloadImages()
  },

  methods: {
    next() {
      this.swiper.slideNext()
    },

    prev() {
      this.swiper.slidePrev()
    },

    goToSlide(index) {
      this.swiper.slideTo(index)
    },

    onSwiper(swiperInstance) {
      this.swiper = swiperInstance
    },

    setCurrentSlide() {
      if (this.swiper) {
        let slideIndex = this.swiper.realIndex
        if (slideIndex >= this.hotelSlices.length)
          slideIndex = 0
        else if (slideIndex < 0)
          slideIndex = this.hotelSlices.length - 1

        this.showHotelInfo = false
        setTimeout(() => {
          this.currentSlide = slideIndex
          this.showHotelInfo = true
        }, 500)
      }
    },

    preloadImages() {
      return new Promise((resolve, reject) => {
        (function loadEach(images, index) {
          if (index < images.length && images[index]) {
            const img = new Image()
            img.src = images[index]
            images[index] = img
            images[index].onload = function () {
              loadEach(images, ++index)
            }
            images[index].onerror = err => reject(err)
          }
          else {
            resolve(images)
          }
        })(this.hotelImages, 0)
      })
    },
  },

}
</script>

<template>
  <div class="mb-24 ">
    <div
      id="hotel-index" class="relative" :class="{
        'lg:mt-24':
          (fields.hotelCardPosition.value === 'top-left'
            || fields.hotelCardPosition.value === 'top-right')
          || (fields.displayTitleCard.value
            && (fields.titleCardPosition.value === 'top-left'
              || fields.titleCardPosition.value === 'top-right')),
      }"
    >
      <!-- hotels -->
      <div
        v-if="fields.slider.value
          && slice.elements[0]
          && slice.elements[0].elements.length
        " class="pb-16 lg:pb-50"
      >
        <div class="relative">
          <Swiper
            ref="mySwiper" slides-per-view="auto" :space-between="0" :loop="hotelSlices.length > 1" :centered-slides="true"
            @active-index-change="setCurrentSlide"
            @swiper="onSwiper"
          >
            <SwiperSlide v-for="(element, key) in hotelSlices" :key="key" class="max-h-[600px]">
              <VoixSlice :ref="`hotelSlide-${key}`" :slice="element" :slice-index="key" class="w-full" :slider="true" />
            </SwiperSlide>
          </Swiper>
          <div
            v-if="hotelSlices.length > 1"
            class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center px-8 -translate-y-12"
          >
            <button
              class="pointer-events-auto slider-button mr-4 mt-4 text-orange-900 hover:text-white "
              @click="prev"
            >
              <svg class="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              class="pointer-events-auto slider-button mt-4 text-orange-900 hover:text-white "
              :data-testid="swiper !== null ? 'glue-hotel-index-next-button' : ''"
              @click="next"
            >
              <svg
                class="w-7 h-7 transform rotate-180" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            class="absolute z-20 inset-0 hidden lg:grid gap-16 lg:grid-cols-12 pointer-events-none"
          >
            <div
              class="relative lg:col-span-4 " :class="{
                'lg:top-0 lg:left-0': fields.titleCardPosition.value === 'top-left',
                'lg:top-0 lg:col-start-6':
                  fields.titleCardPosition.value === 'top-right',
                'lg:flex lg:items-end lg:left-0':
                  fields.titleCardPosition.value === 'bottom-left',
                'lg:flex lg:items-end lg:col-start-6':
                  fields.titleCardPosition.value === 'bottom-right',
              }"
            >
              <div
                v-if="fields.displayTitleCard.value"
                class="bg-white py-10 px-12 z-10 col-span-4"
                :class="{
                  'lg:-translate-y-1/2': fields.titleCardPosition.value === 'top-left' || fields.titleCardPosition.value === 'top-right',
                }"
              >
                <div class="font-serif text-xl lg:text-3xl tracking-wider italic text-red-300">
                  {{ fields.subTitle.value }}
                </div>
                <div class="headline-3 text-zinc-700 mb-4">
                  {{ fields.title.value }}
                </div>
                <div class="body text-zinc-600 w-4/5">
                  {{ fields.description.value }}
                </div>
              </div>
            </div>
          </div>

          <div class="pointer-events-none lg:absolute z-20 inset-0 grid gap-16 lg:grid-cols-12">
            <div
              class="relative lg:col-span-7 " :class="{
                'lg:top-0 lg:left-0': fields.hotelCardPosition.value === 'top-left',
                'lg:top-0 lg:col-start-6':
                  fields.hotelCardPosition.value === 'top-right',
                'lg:flex lg:items-end lg:left-0':
                  fields.hotelCardPosition.value === 'bottom-left',
                'lg:flex lg:items-end lg:col-start-6':
                  fields.hotelCardPosition.value === 'bottom-right',
              }"
            >
              <div
                class="pointer-events-auto bg-white py-10 max-w-6xl px-8" :class="{
                  'lg:-translate-y-1/2': fields.hotelCardPosition.value === 'top-left' || fields.hotelCardPosition.value === 'top-right',
                  'hotel-theme-cove': currentHotelFields.title.value.includes('Cove'),
                  'hotel-theme-royal': currentHotelFields.title.value.includes('Royal'),
                  'hotel-theme-coral': currentHotelFields.title.value.includes('Coral'),
                  'hotel-theme-reef': currentHotelFields.title.value.includes('Reef'),
                  'hotel-theme-harborside': currentHotelFields.title.value.includes('Harborside'),
                }"
              >
                <div class="lg:grid lg:grid-cols-12 lg:gap-4 px-12 lg:px-0">
                  <div class="col-span-5 lg:col-span-4 xl:col-span-3 lg:border-b-0 lg:border-r border-gray-200 flex items-center justify-center">
                    <div
                      v-if="currentHotel"
                      class="p-8 flex flex-col lg:flex-row items-center justify-center"
                    >
                      <div>
                        <a :href="currentHotelFields.link.value">
                          <VoixMedia :field="currentHotelFields.logo" style="width: 200px" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div v-if="currentHotel" class="text-center lg:text-left col-span-7 lg:col-span-8 xl:col-span-6 flex flex-col justify-center">
                    <div class="headline-2 text-zinc-700 mb-4">
                      {{ currentHotelFields.magazineTitle.value }}
                    </div>
                    <div
                      v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm'"
                      class="body text-zinc-600 mb-12 md:mb-0"
                    >
                      {{ currentHotelFields.magazineDescription.value }}
                    </div>
                    <div class="xl:hidden mt-4">
                      <a
                        v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm' && currentHotelFields.link.value" :href="currentHotelFields.link.value"
                        class="inline-block btn btn-xs hotel-theme-bg text-white"
                      >
                        Explore {{ currentHotelFields.title.value }}
                      </a>
                    </div>
                  </div>
                  <div class="hidden xl:flex col-span-3  justify-center items-center">
                    <div class="flex items-center justify-between">
                      <div class="text-right mb-6" />
                      <a
                        v-if="$voix.breakpoint !== 'default' && $voix.breakpoint !== 'sm' && currentHotelFields.link.value" :href="currentHotelFields.link.value"
                        class="btn btn-xs hotel-theme-bg text-white"
                      >
                        Explore {{ currentHotelFields.title.value }}
                      </a>
                    </div>
                    <template v-if="$voix.breakpoint === 'default' || $voix.breakpoint === 'sm'">
                      <div class="body text-zinc-600 mb-12 md:mb-0">
                        {{ currentHotelFields.magazineDescription.value }}
                      </div>
                      <div class="w-full lg:w-2/5">
                        <div class="flex justify-center lg:justify-end">
                          <a
                            :href="currentHotelFields.link.value"
                            class="glue-btn btn-ghost border-zinc-600 text-xs tracking-widest p-3 pt-5 px-12 text-zinc-700"
                          >
                            Explore {{ currentHotelFields.title.value }}
                          </a>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="border-white xl:mb-20">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.swiper-slide {
  width: 100%;
}
</style>
