<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Image Four By Three', group: 'Media', layouts: ['Atlantisbahamas'] },
      description: 'Copy on top with image that is clipped on the left or right that also has copy',
      tags: ['Image'],
      fields: {
        isRight: { label: 'Clips to the right', type: 'checkbox' },
        copySubTitle: { type: 'text', label: 'Sub-Title' },
        copyTitle: { type: 'text', label: 'Title' },
        copyDescription: { type: 'textarea', label: 'Description' },
        link: { type: 'link', label: 'Image Button Link', enabled: false },
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 925, height: 580 }, sm: { width: 315, height: 500 } } },
      },
      templates: [{ label: 'Image Four By Three', fields: { image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-couple-royal-baths-pool-underwater-swimming.jpg' } } }],
    })
  },
}
</script>

<template>
  <SlicesGlueGenericCardsImageCard
    :fields="fields"
    :slice="slice"
  />
</template>
