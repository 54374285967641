<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'NPIWFF Slider', group: 'Sliders', layouts: ['Atlantisbahamas', 'NPIWFF'] },
      preview: 'SlicesWffSlider.jpg',
      description: 'WFF Slider',
      tags: ['Slider'],

      slots: [{ name: 'default' }],
      fields: {
        numberOfSlides: {
          type: 'number',
          label: 'Number of Slides',
          instructions: '"Number of slides to display. 0 will display all slides."',
        },
        title: { type: 'text', label: 'Title' },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
        disableSlider: { type: 'checkbox', label: 'Disable Slider' },
      },
      templates: [{
        label: 'Slider',
        fields: {
          introduction: { value: '<p>The Introduction<\/p>' },
          title: { value: 'THE 25K ITINERARY' },
        },
      }],
    })
  },
}
</script>

<template>
  <div>
    <wff-wff-slider :devise="devise">
      <slot />
    </wff-wff-slider>
  </div>
</template>
