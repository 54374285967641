<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Section Header', group: 'Copy', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesTwentyFifthAnniversarySectionHeader.jpg',
      description: '25th Anniversary Section Header',
      tags: ['Headline', '25th Anniversary', 'Copy'],

      fields: {
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        icon: { type: 'select', label: 'Icon', options: { dining: 'Dining', discover: 'Discover', events: 'Events', immerse: 'Immerse', plan: 'Plan' } },
        leadingText: { type: 'text', label: 'Leading Text', enabled: false },
        leadingLink: { type: 'link', label: 'Leading Link (Leading Text must be enabled)', enabled: false },
      },
      templates: [{ label: 'Section Header', fields: { copy: { value: '<p>Enjoy <strong>The Royal 25th Celebration<\/strong> surrounded by pure elegance<br>and the breathtaking beauty of Atlantis Paradise Island.<\/p>' }, icon: { value: 'immerse' }, title: { value: 'immerse' } } }],
    })
  },
}
</script>

<template>
  <div class="flex flex-col items-center p-8 lg:p-16 font-sans bg-orange-50">
    <div>
      <twenty-fifth-anniversary-icon-dining v-if="fields.icon.value === 'dining'" class="w-24 text-glueblue-600" />
      <twenty-fifth-anniversary-icon-discover v-if="fields.icon.value === 'discover'" class="w-24 text-glueblue-600" />
      <twenty-fifth-anniversary-icon-events v-if="fields.icon.value === 'events'" class="w-24 text-glueblue-600" />
      <twenty-fifth-anniversary-icon-immerse v-if="fields.icon.value === 'immerse'" class="w-24 text-glueblue-600" />
      <twenty-fifth-anniversary-icon-plan v-if="fields.icon.value === 'plan'" class="w-24 text-glueblue-600" />
    </div>

    <div class="-mt-4 text-[72px] font-annv font-thin">
      {{ fields.title.value }}
    </div>

    <div class="max-w-[700px] font-sans text-xl font-light text-center" v-html="fields.copy.value" />

    <div v-if="fields.leadingText.enabled" class="mt-8 flex flex-col items-center space-y-8">
      <a v-if="fields.leadingLink.enabled" :href="fields.leadingLink.value?.href" :target="fields.leadingLink.value?.target" class="block btn-ghost !border-glueblue-600 !text-glueblue-600 btn-xs">
        {{ fields.leadingText.value }}
      </a>
      <div v-else class=" btn-ghost !border-glueblue-600 !text-glueblue-600 btn-xs">
        {{ fields.leadingText.value }}
      </div>

      <div class="h-[100px] border-r border-glueblue-600" />
    </div>
  </div>
</template>
