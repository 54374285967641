<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      posts: [],
      categories: [],
      tags: [],
      filters: {
        category: '',
        tag: '', // Blue Project
      },
      pagination: {
        currentPage: 1,
        itemsPerPage: 20,
      },
    }
  },
  computed: {
    topFivePosts() {
      const featuredPosts = this.posts.filter((post) => {
        return post.categories.find((category) => {
          return category.name === 'Featured'
        })
      })
      return featuredPosts.slice(0, 5)
    },

    filteredPosts() {
      const filtered = this.posts.filter((post) => {
        if (this.filters.category && this.filters.tag) {
          return (
            post.categories.some(category => category.id === this.filters.category)
            && post.tags.some(tag => tag.id === this.filters.tag)
          )
        }

        if (this.filters.category) {
          return post.categories.some(
            category => category.id === this.filters.category,
          )
        }

        if (this.filters.tag)
          return post.tags.some(tag => tag.id === this.filters.tag)

        return true
      })

      const startIndex = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage
      return filtered.slice(startIndex, startIndex + this.pagination.itemsPerPage)
    },

    totalPages() {
      const filtered = this.posts.filter((post) => {
        if (this.filters.category && this.filters.tag) {
          return (
            post.categories.some(category => category.id === this.filters.category)
            && post.tags.some(tag => tag.id === this.filters.tag)
          )
        }

        if (this.filters.category) {
          return post.categories.some(
            category => category.id === this.filters.category,
          )
        }

        if (this.filters.tag)
          return post.tags.some(tag => tag.id === this.filters.tag)

        return true
      })
      return Math.ceil(filtered.length / this.pagination.itemsPerPage)
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Blog Index', group: 'Blue Project', layouts: ['BlueProject'] },
      preview: 'SlicesBlueprojectBlogIndex.jpg',
      tags: ['Blog', 'Index'],
      description: 'Blue Project Blog Index',
      fields: [],
      templates: [{ label: 'Blog Index', fields: [] }],
    })

    this.fetchPosts()
  },

  methods: {
    async fetchPosts() {
      const data = await $voixFetch(`/api/devise/blog-posts/data`)
      this.posts = data.posts
      this.categories = data.categories
      this.tags = data.tags
    },
    buildURL(post) {
      if (post.in_page_target)
        return `${post.permalink}${post.in_page_target}`

      return post.permalink
    },
    changePage(page) {
      this.pagination.currentPage = page
    },
  },
}
</script>

<template>
  <div class="container max-w-[1200px] mx-auto px-4 lg:px-16 pb-12">
    <div>
      <div class="grid grid-cols-1 gap-24">
        <div
          v-for="(post) in filteredPosts"
          :key="post.id"
          class="relative group overflow-hidden grid grid-cols-12  gap-12"
        >
          <div class=" col-span-4">
            <div class="relative aspect-4/3">
              <img :src="post.thumbnail" class="w-full h-full object-cover object-top">
            </div>
          </div>
          <div
            class="col-span-7 duration-500 group-hover:translate-x-4 ease-out"
          >
            <div
              class="text-2xl text-glueblue-600 uppercase font-sans leading-snug antialiased tracking-wide"
            >
              {{ post.title }}
            </div>
            <div
              class="pt-2 mt-4 font-sans2 font-light"
              v-html="post.short_description"
            />
            <div class="pt-8">
              <a class="block" :href="buildURL(post)">
                Read More <Icon name="heroicons:arrow-right" class="group-hover:tranlate-x-4 duration-200 ease-out w-4 h-4" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div v-if="totalPages > 1" class="col-span-12 flex justify-center items-center gap-4 mt-8">
        <button
          :disabled="pagination.currentPage === 1"
          class="px-4 py-2 text-glueblue-600 font-sans2 uppercase tracking-wider disabled:opacity-50 disabled:cursor-not-allowed hover:bg-glueblue-700 transition-colors"
          @click="changePage(pagination.currentPage - 1)"
        >
          Previous
        </button>

        <div class="flex gap-2">
          <button
            v-for="page in totalPages"
            :key="page"
            class="px-4 py-2 transition-colors" :class="[
              pagination.currentPage === page
                ? 'bg-glueblue-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
            ]"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </div>

        <button
          :disabled="pagination.currentPage === totalPages"
          class="px-4 py-2 text-glueblue-600 font-sans2 uppercase tracking-wider disabled:opacity-50 disabled:cursor-not-allowed hover:bg-glueblue-700 transition-colors"
          @click="changePage(pagination.currentPage + 1)"
        >
          Next
        </button>
      </div>
    </div>

    <div v-if="posts.length < 1" class="col-span-12 h-48">
      <div class="text-center">
        <h2 class="text-2xl font-sans2 font-light">
          Loading posts...
        </h2>
      </div>
    </div>
  </div>
</template>
