<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Contact Card', group: 'Cards', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueGenericCardsContactCard.jpg',
      description: 'Empty container with child slices',
      tags: ['Card', 'Contact'],
      fields: {
        title: { type: 'text', label: 'Title' },
        subtitle: { type: 'text', label: 'Subtitle' },
        description: { type: 'wysiwyg', label: 'Description' },
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 500, height: 500 }, lg: { width: 500, height: 500 }, sm: { width: 500, height: 500 } } },
        phone: { type: 'text', label: 'Phone Number', enabled: false },
        email: { type: 'text', label: 'Email', enabled: false },
        linkedin: { type: 'text', label: 'LinkedIn URL', enabled: false },
      },
      templates: [{
        label: 'Contact Card',
        fields: {
          description: { value: '<p><br><br><\/p>' },
          email: { enabled: true, value: 'Jack.Edelman@atlantisparadise.com' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Casino\/Casino_Host\/CasinoHost_JackEdelman.jpg' },
          phone: { enabled: true, value: '877-508-1593' },
          title: { value: 'Jack Edelman' },
          subtitle: { value: 'Welcome to Atlantis' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="w-full md:w-[400px] bg-white">
    <!-- Image -->
    <VoixMedia :field="fields.image" class="object-cover w-full md:h-[500px]" />

    <!-- Title and Description -->
    <div class="flex flex-col justify-between items-center h-fit px-8 py-4">
      <div class="w-full h-fit">
        <!-- Title -->
        <div class="font-sans font-semibold text-lg uppercase">
          {{ fields.title.value }}
        </div>

        <!-- Subtitle -->
        <div class="font-sans2 font-light text-lg mt-2">
          {{ fields.subtitle.value }}
        </div>

        <!-- Description -->
        <div
          class="font-sans2 text-base font-light w-full mt-6 overflow-hidden"
          v-html="fields.description.value"
        />
      </div>

      <!-- Contact Info -->
      <div class="flex flex-col justify-start items-start w-full h-fit space-y-2 py-4 text-gray-600">
        <!-- Phone -->
        <div
          v-if="fields.phone.enabled"
          class="flex space-x-2 items-center text-base font-sans font-normal no-decoration"
        >
          <svg
            class="w-4 h-4 sm:w-5 sm:h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
          <a :href="`tel:${fields.phone.value}`">{{ fields.phone.value }}</a>
        </div>

        <!-- Email -->
        <div
          v-if="fields.email.enabled"
          class="flex space-x-2 items-center text-base font-sans font-normal"
        >
          <svg
            class="w-4 h-4 sm:w-5 sm:h-5 relative top-[2px]"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <a :href="`mailto:${fields.email.value}`" class="text-base font-sans font-normal underline">Contact Me</a>
        </div>

        <!-- LinkedIn -->
        <div
          v-if="fields.linkedin.enabled"
          class="flex space-x-2 items-center text-base font-sans font-normal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
          <a :href="fields.linkedin.value" target="_blank" class="text-base font-sans font-normal underline">Connect On LinkedIn</a>
        </div>
      </div>
    </div>
  </div>
</template>
