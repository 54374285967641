<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      posts: [],
      categories: [],
      tags: [],
      filters: {
        category: null,
        tag: null,
      },
    }
  },
  computed: {
    topFivePosts() {
      const featuredPosts = this.posts.filter((post) => {
        return post.categories.find((category) => {
          return category.name === 'Featured'
        })
      })
      return featuredPosts.slice(0, 5)
    },

    filteredPosts() {
      return this.posts.filter((post) => {
        if (this.filters.category && this.filters.tag) {
          return (
            post.categories.some(category => category.id === this.filters.category)
            && post.tags.some(tag => tag.id === this.filters.tag)
          )
        }

        if (this.filters.category) {
          return post.categories.some(
            category => category.id === this.filters.category,
          )
        }

        if (this.filters.tag)
          return post.tags.some(tag => tag.id === this.filters.tag)

        return true
      })
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Blog Index', group: 'Blog', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesGlueBlogBlogIndex.jpg',
      tags: ['Blog', 'Index'],
      slots: [{ name: 'default' }],
      description: 'Blog Index',
      fields: [],
      templates: [{ label: 'Blog Index', fields: [] }],
    })

    this.fetchPosts()
  },

  methods: {
    async fetchPosts() {
      const data = await $voixFetch(`/api/devise/blog-posts/data`)
      this.posts = data.posts
      this.categories = data.categories
      this.tags = data.tags
    },
  },
}
</script>

<template>
  <div>
    <GlueBlogBlogHeroSlider v-if="topFivePosts.length > 0" :posts="topFivePosts" />
    <div class="mt-16">
      <slot />
    </div>
    <GlueBlogBlogFilters
      v-if="categories.length > 0"
      v-model="filters"
      :categories="categories"
      :tags="tags"
    />
    <GlueBlogBlogPosts :posts="filteredPosts" />
  </div>
</template>
