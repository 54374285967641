<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Preteens Aka', group: 'Family', layouts: ['Atlantisbahamas'] },
      description: 'Toddlers AKA Widget',
      tags: ['Navigation'],
      preview: 'SlicesFamilyPreteensAka.jpg',
      fields: {
        cooksAtPlayImage: { type: 'media', label: 'Cooks at Play Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        performanceRoomImage: { type: 'media', label: 'Performance Room Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        gameRoomImage: { type: 'media', label: 'Game Room Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        artsCraftsImage: { type: 'media', label: 'Art and Crafts Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
        eveningProgrammingImage: { type: 'media', label: 'Evening Programming Image', breakpoints: { lg: { width: 1160, height: 690 }, default: { width: 315, height: 315 } } },
      },
      templates: [{ label: 'Preteens Aka', fields: { artsCraftsImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Preteens\/Atlantis_Kids_Adventures\/Gallery\/preteens_aka_artscrafts.jpg' }, cooksAtPlayImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/aka.jpg' }, eveningProgrammingImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Preteens\/Atlantis_Kids_Adventures\/Hero\/Preteens_AtlantisKidsAdventures_PerformanceRoom.jpg' }, gameRoomImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/atlantis-aka-game-room-children-playing-video-game.jpg' }, performanceRoomImage: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/legacy\/atlantis\/Kids\/Preteens\/Atlantis_Kids_Adventures\/Gallery\/preteens_aka_performancebackstage.jpg' } } }],
    })
  },
}
</script>

<template>
  <div>
    <glue-family-preteens-aka :devise="devise" />
  </div>
</template>
