<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Bottom Right Copy', group: 'Copy', layouts: ['Atlantisbahamas', 'NPIWFF', 'AtlantisMobileApp'] },
      description: 'Bottom-Right Copy for Slider',
      tags: ['Copy'],
      fields: { italicText: { label: 'Italic Text', type: 'text', enabled: false }, bigText: { label: 'Big Text', type: 'text', enabled: false }, description: { label: 'Description', type: 'wysiwyg', enabled: false }, link: { label: 'Link', type: 'link', enabled: false }, isRight: { label: 'Is Right?', type: 'checkbox', default: true }, upHigh: { label: 'Rides High?', type: 'checkbox', default: true } },
      templates: [{
        label: 'Bottom Right Copy',
        fields: {
          isRight: { value: true },
          upHigh: { value: true },
          bigText: { enabled: true, value: 'A PLACE LIKE NO OTHER' },
          italicText: { enabled: true, value: 'about atlantis' },
          description: { enabled: true, value: 'atlantis description' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="relative">
    <div
      class="absolute z-30 px-12 bottom-0 text-white grid grid-cols-6 lg:grid-cols-12 gap-16"
      :class="{
        'right-0 md:text-right md:mr-24 ': fields.isRight.value,
        'ml-6 md:ml-24 left-0': !fields.isRight.value,
        'mb-3 lg:mb-48': fields.upHigh.value,
        'mb-3 lg:mb-20': !fields.upHigh.value,
      }"
    >
      <div class="col-span-6">
        <div
          v-if="fields.bigText.enabled"
          class="headline-2 pb-6"
        >
          <div
            v-if="fields.italicText.enabled"
            class="subtitle lowercase"
          >
            {{ fields.italicText.value }}
          </div>

          {{ fields.bigText.value }}
        </div>
        <div
          v-if="fields.description.enabled"
          class="text-xs md:text-sm font-sans2 antialiased"
          v-html="fields.description.value"
        />
        <a
          v-if="fields.link.enabled"
          class="uppercase btn-ghost text-white btn-active text-xs font-normal font-sans leading-none my-8 py-4 px-8 inline-block tracking-widest pointer-events-auto"
          :href="fields.link.value?.href"
          :target="fields.link.value.target"
        >
          {{ fields.link.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
