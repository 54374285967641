<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Categorized Gallery Image', group: 'Galleries', layouts: ['Atlantisbahamas', 'NPIWFF'] },
      preview: 'SlicesGlueGalleriesCategorizedGalleryImage.jpg',
      description: 'Categorized Gallery Image',
      fields: {
        image: { type: 'media', label: 'Image', editorLabel: true, breakpoints: { default: { width: 780, height: 780 }, sm: { width: 330, height: 330 }, lg: { width: 1080, height: 720 } } },
        category: { type: 'select', label: 'Old Category', options: { 'resorts': 'Resorts', 'accommodations': 'Accommodations', 'experiences': 'Experiences', 'dining': 'Dining', 'pools-and-beaches': 'Pools & Beaches', 'meetings-and-celebrations': 'Meetings & Celebrations' } },
        categoryEntertainment: { label: 'Category Entertainment?', type: 'checkbox' },
        categoryAccommodations: { label: 'Category Accommodations?', type: 'checkbox' },
        categoryExperiences: { label: 'Category Experiences?', type: 'checkbox' },
        categoryDining: { label: 'Category Dining?', type: 'checkbox' },
        categoryPoolsAndBeaches: { label: 'Category Pools and Beaches?', type: 'checkbox' },
        categoryMeetingsAndCelebrations: { label: 'Category Meetings and Celebrations?', type: 'checkbox' },
      },
      templates: [{
        label: 'Categorized Gallery Image',
        fields: {
          category: { value: 'experiences' },
          categoryExperiences: { value: true },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/dolphin-cay\/atlantis-marine-programs-dolphin-cay-kayak-with-dolphins.jpg' },
        },
      }],
    })
  },
}
</script>

<template>
  <div />
</template>
