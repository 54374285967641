<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Grid Of Cards Red Title Card', group: 'Cards', layouts: ['AtlantisMobileApp', 'Atlantisbahamas'] },
      preview: 'SlicesGlueGenericCardsGridOfCardsRedTitleCard.jpg',
      description: 'Grid of Cards Red Title Card',
      tags: ['Card', 'Grid'],
      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'wysiwyg', label: 'Description' },
        link: { type: 'link', label: 'Link', enabled: false },
        instructions: { type: 'wysiwyg', label: 'Instructions' },
      },
      templates: [{
        label: 'Grid Of Cards Red Title Card',
        fields: {
          description: { value: '<p>7:00pm - Welcome Reception<br>Private reception<\/p><p>11:00am-5:00pm -  Registration<br>Riders Only<\/p>' },
          instructions: { value: '<p>In sollicitudin imperdiet aliquet. Nam scelerisque consequat pulvinar. Etiam a cursus elit, vitae finibus lorem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas lobortis fringilla lectus id suscipit. Vestibulum euismod rutrum quam, laoreet rhoncus felis cursus eu. Quisque congue vestibulum mauris at finibus.</p>' },
          title: { value: 'Friday November 4' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="font-sans2 bg-white p-10 flex flex-col justify-between">
    <div>
      <div class="font-sans text-xl font-bold text-red-500 uppercase border-b border-zinc-200">
        {{ fields.title.value }}
      </div>
      <div class="mt-4">
        <div class="prose" v-html="fields.description.value" />
        <div v-if="fields.link.enabled" class="mt-4 pb-8">
          <a :href="fields.link.url" class="inline-block text-center border border-zinc-500 text-zinc-700 font-bold px-8 py-5 leading-snug uppercase focus:outline-none font-sans text-2xs min-w-[180px]">{{ fields.link.value?.text }}</a>
        </div>
      </div>
    </div>
    <div class="mt-8 pb-4 prose prose-sm text-red-500 border-b border-zinc-100" v-html="fields.instructions.value" />
  </div>
</template>
