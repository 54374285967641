<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
    }
  },

  mounted() {
    defineSlice({
      name: { label: 'Mosaic Side By Side Cards', group: 'Cards', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesCardsMosaicSideBySideCards.jpeg',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: { width: 800, height: 800 },
            lg: { width: 1062, height: 1062 },
          },
        },
        imageOnLeft: {
          type: 'checkbox',
          label: 'Image on Left',
          default: true,
        },
        superscript: {
          type: 'text',
          label: 'Superscript',
        },
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
        },
        copy: { type: 'wysiwyg', label: 'Copy' },
        link: { type: 'link', label: 'Link', enabled: true },
      },

      templates: [{
        label: 'Card',
        fields: {
          image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/legacy/atlantis/Casino/Overview/Overview_SpecialOffers_532x309.jpg' },
          superscript: { value: 'CONDE NAST' },
          title: { value: 'YOUR MOMENTS MATTER' },
          copy: { value: 'Lorem Ipsum' },
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '/new-page' } },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="container py-24">
    <div class="flex flex-col space-y-20 ">
      <div
        class="w-full mx-auto flex flex-col items-end" :class="{
          'lg:flex-row-reverse': !fields.imageOnLeft.value,
          'lg:flex-row': fields.imageOnLeft.value,
        }"
      >
        <div class="relative z-10 aspect-square w-full lg:-translate-y-12">
          <VoixMedia :field="fields.image" :alt="fields.title.value" class="w-full h-full object-cover" />
        </div>
        <div
          class="px-24 py-24 w-full lg:max-w-lg bg-glueblue-600 text-white" :class="{
            'lg:-translate-x-12': fields.imageOnLeft.value,
            'lg:translate-x-12': !fields.imageOnLeft.value,
          }"
        >
          <div class="text-3xl leading-[1em] font-sans2 font-light uppercase">
            <div v-if="fields.superscript.value" class="subtitle text-sans2 text-xs">
              {{ fields.superscript.value }}
            </div>
            {{ fields.title.value }}
          </div>
          <VoixWysiwyg :field="fields.copy" />
          <a
            v-if="fields.link.enabled"
            :href="fields.link.value?.href"
          >
            <div class=" mt-8 btn btn-ghost btn-xs">
              {{ fields.link.value?.text }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
