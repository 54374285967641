<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    backgroundImage() {
      if (this.fields.hotel.value === 'cove')
        return `url(/imgs/hotels/backgrounds/cove.svg)`

      if (this.fields.hotel.value === 'coral')
        return `url(/imgs/hotels/backgrounds/hotel-theme-coral-bg.png)`

      if (this.fields.hotel.value === 'royal')
        return `url(/imgs/hotels/backgrounds/hotel-theme-royal-bg.png)`

      return ''
    },
    backgroundSize() {
      if (this.fields.hotel.value === 'cove')
        return '1600px'

      if (this.fields.hotel.value === 'coral')
        return '1600px'

      return ''
    },
    opacity() {
      if (this.fields.hotel.value === 'cove')
        return '0.3'

      return '1'
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Hotel Wrapper', group: 'Hotels', layouts: ['Atlantisbahamas', 'TheCove'] },
      preview: 'SlicesGlueStayHotelWrapper.jpg',
      slots: [{ name: 'default' }],
      description: 'Wrapper for the hotel stay section',
      fields: {
        hotel: {
          type: 'select',
          label: 'Hotel',
          options: {
            cove: 'The Cove',
            royal: 'The Royal',
            coral: 'The Coral',
            reef: 'The Reef',
            harborside: 'Horborside Resort',
          },
        },
        showLogo: { type: 'checkbox', label: 'Show Logo', default: true },
      },
      templates: [{
        label: 'Hotel Wrapper',
        fields: { hotel: { value: 'cove' } },
      }],
    })
  },
}
</script>

<template>
  <div
    class="relative lg:-mt-24 py-24 hotel-wrapper" :class="[
      `hotel-theme-${fields.hotel.value}`,
    ]"
  >
    <div class="absolute inset-0 z-0" :style="{ backgroundImage, backgroundSize, opacity }">
      <img
        v-if="fields.hotel.value === 'reef'"
        src="/imgs/hotels/backgrounds/hotel-theme-reef-top.png"
        class="absolute top-0 right-0" alt=""
      >
      <img
        v-if="fields.hotel.value === 'reef'"
        src="/imgs/hotels/backgrounds/hotel-theme-reef-bottom.png"
        class="absolute bottom-0 left-0" alt=""
      >
    </div>
    <div class="relative">
      <div v-if="fields.showLogo.value" class="py-12 hotel-theme-logo flex justify-center">
        <LogosCoveLogo v-if="fields.hotel.value === 'cove'" class="h-8" />
        <LogosReefLogo v-if="fields.hotel.value === 'reef'" class="h-24" />
        <LogosCoralLogo v-if="fields.hotel.value === 'coral'" class="h-20" />
        <LogosRoyalLogo v-if="fields.hotel.value === 'royal'" class="h-24" />
        <LogosHarborsideLogo v-if="fields.hotel.value === 'harborside'" class="h-24" />
      </div>
      <div>
        <ClientOnly>
          <VoixSlice
            v-for="(element, key) in slice.elements"
            :key="element.id"
            :slice="element"
            :slice-index="key"
            :hotel="fields.hotel.value"
          />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
