<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Atlantis Annv Logo', group: 'Branding', layouts: ['Atlantisbahamas'] },
      preview: 'SlicesTwentyFifthAnniversaryAtlantisAnnvLogo.jpg',
      description: 'Atlantis Logo',
      tags: ['Logo', '25th Anniversary'],
      fields: [],
      templates: [{ label: 'Atlantis Annv Logo', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="absolute z-50 top-0 left-0 right-0 flex justify-center">
    <div class="big-container">
      <a href="/" class="block px-4 py-2 lg:py-12 lg:px-12 ">
        <glue-svgs-atlantis-annv-logo class="w-[150px] lg:w-[250px] text-white" />
      </a>
    </div>
  </div>
</template>
